.rates-section {
  overflow-x: auto;
  border-top: 5px solid $colour-standard-turquoise;
  border-radius: 5px;
  box-shadow: 5px 5px 22px #0000001a;

  background-color: white;
  padding: 0em 1em;
  & h4 {
    margin-bottom: 0;
  }

  margin-bottom: 0.5rem;
  .defaults-page-title {
    h4 {
      width: 30em;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @include breakpoint-sm {
        width: 14em;
      }
    }
  }

  .rates-table-container {
    .rates-table-header {
      margin-bottom: 0 !important;
      margin-top: 0.1em !important;
      background-color: $colour-white-turquoise;
      padding-top: 0.1em;
      padding-bottom: 0.1em;
      .row {
        padding-top: 0.1em;
        padding-bottom: 0.1em;
      }
      p {
        white-space: nowrap;
      }
    }
    .rates-table {
      margin-top: 0.25rem;
      margin-bottom: 0;
      .row {
        padding-top: 0.1em;
        padding-bottom: 0.1em;

        &:nth-child(odd) {
          background-color: $colour-white-turquoise;
        }
      }
    }
  }

  .yield-rate-form {
    margin-top: 0.2rem;
    margin-bottom: 1.25rem;
    .field-row {
      display: flex;
      justify-content: space-around;
    }
    p.month,
    p.quarter {
      width: 3em;
      margin-bottom: 0;
    }

    .field {
      margin-bottom: 0;
      .ui.input {
        input {
          padding: 0.05em 0.15em !important;
          margin-top: 0.1em;
          margin-bottom: 0.1em;
        }
      }
    }
  }
}

.yields-container {
  .rates-section {
    .defaults-page-title {
      margin: 0.25em 0;
      align-items: center;
    }
  }
}
