.office-general-settings {
  margin-left: 2em;
  margin-right: 2em;

  .capital-section-panel {
    margin: 1em 0 2em 0;
    padding: 1em !important;
  }
  .payment-method-panel {
    margin: 2em 0 2em 0;
    padding: 1em !important;
  }
}

.general-settings-tabs {
  &.ui.menu {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border-radius: 0 !important;
    border: none !important;
  }
  &.ui.vertical.menu {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
  .item {
    max-width: 12em !important;
    padding: 0.5em !important;
    border: 3px solid $colour-light-grey !important;
    margin-bottom: 0.5em !important;

    &.active {
      background-color: $colour-white-turquoise !important;
    }
  }
}

.defaults-grid {
  button.ui.button {
    &.positive-title-action {
      background-color: $positive-color;
    }
    &.cancel-title-action {
      background-color: transparent;
      color: $text-color;
    }
  }

  .ui.input {
    > input {
      text-align: right;
    }
  }
  .field label,
  .field p {
    font-size: 1em !important;
    color: $text-color !important;
  }

  .column.defaults-clause > .field {
    > .ui.input > input {
      text-align: left;
    }
    > .read-only {
      max-width: unset !important;
    }
  }
}

.defaults-page-title {
  button.ui.button {
    margin-right: 0 !important;
    &.positive-title-action {
      background-color: $positive-color;
    }
    &.cancel-title-action {
      background-color: transparent;
      color: $text-color;
    }
  }
}

.extra-settings-panel {
  margin-top: 2em;
}

.extra-settings-panel div.content-container {
  width: 99% !important;
  background: #ffffff;
  border: none !important;
  box-shadow: none !important;
}

.extra-settings-panel .button.ui.primary.button.icon {
  margin-right: 0 !important;
  max-height: 2em;
}
.extra-settings-panel .button.ui.button.cancel-title-action {
  margin-right: 0 !important;
  max-height: 2em;
}
h4 {
  &.title {
    font-size: 2em;
  }
  &.settings-title {
    font-size: 20px !important;
  }
}
.content-panel .content-panel h4 {
  &.settings-title {
    font-size: 16px !important;
  }
}
.section-1 > h5,
.section-2 > h5,
.section-3 > h5 {
  padding-top: 0.5em;
  font-size: 1.25em;
}

.required-star {
  color: red;
  font-weight: bold;
  padding-left: 0.25em;
}

.general-settings-menu-column {
  min-width: 200px !important;
}

.settings-header {
  font-size: 24px;
}
.settings-menu-header .finance-menu-header {
  margin-left: 0.25rem;
  margin-bottom: 0.5rem;
}
div.settings-grid {
  .title {
    display: flex;
  }
  .settings-grid-nav-header {
    :hover {
      cursor: pointer;
    }
    text-decoration: underline;
    .column {
      width: auto !important;
      border-right: solid black 1px;
    }
    .last-col {
      border-right: 0px;
    }
  }
  .settings-grid-row {
    padding: 0 !important;
    .ui.accordion {
      > .title {
        padding: 0.25em 0 !important;
        .title {
          padding: 0;
          h5 {
            margin-bottom: 0;
          }
        }
      }
      > .active.content {
        margin-bottom: 1em;
      }
      .ui.form {
        .actions.form-header {
          display: flex;
          flex-flow: row wrap;
          justify-content: end;
          margin-bottom: 1em;
        }
      }
    }
    &:last-child {
      margin-bottom: 1em;
    }
  }

  div.row {
    &.two {
      div.column {
        padding-right: 1.5em;
      }
    }
  }
  div.fee-struct-table {
    @include breakpoint-md {
      width: 100%;
    }
    div.dropdown {
      display: inline-flex;
      justify-content: space-between;
      width: 15em;
      > div.divider {
        flex-grow: 1;
      }
      > i.icon {
        flex-grow: 0;
        margin: auto;
      }
    }
    div.header {
      display: inline-flex;
      width: 100%;
      justify-content: space-between;
      div.actions {
        display: inline-flex;
      }
    }
    div.table {
      display: table; //we are actually building a table, this isn't just for display :)
      > div.row {
        display: table-row;
        > div.field {
          display: table-cell;
          padding: 0.25em;
          min-width: 5em;
          border-width: 1px;
          border-color: $colour-grey;
          border-style: none solid solid none;
          &:first-child {
            min-width: 18em;
            border-left-style: solid;
          }
          &:nth-child(2) {
            min-width: 6em;
            max-width: 10em;
          }
          div.input {
            width: 100% !important;
            &.fee-price {
              input {
                &::before {
                  content: "&GBP;";
                }
              }
            }
          }
        }
        &:first-of-type {
          > div.field {
            border-top-style: solid;
            &:first-of-type {
              border-top-left-radius: 3px;
            }
            &:last-of-type {
              border-top-right-radius: 3px;
            }
          }
        }
        &:nth-child(2n) {
          background-color: $colour-offwhite;
        }
        &:last-of-type {
          > div.field {
            &:first-of-type {
              border-bottom-left-radius: 3px;
            }
            &:last-of-type {
              border-bottom-right-radius: 3px;
            }
          }
        }
      }
    }
  }
}

.capital-settings {
  .ui.menu {
    width: 37.5em;
    margin: 10px auto;
    .item {
      font-size: 12px;
    }
    .active.item {
      background-color: $colour-white-turquoise;
    }
  }
}

.pir-markups-dynamic-terms-container {
  display: flex;
  .pir-markups {
    max-width: 600px;
    width: 100%;
  }
  .pir-dynamic-terms {
    margin-left: 3em;
    width: 100%;
  }
}

.pis-billing {
  display: flex;
  justify-content: flex-start;
  gap: 5em;
  margin-bottom: 1em;
  .inline.field > label {
    font-size: 14px !important;
    font-weight: normal !important;
  }
}
