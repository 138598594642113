.cin.side-panel.price-increase-filter-side-panel {
  .react-datepicker-wrapper {
    width: 100% !important;
  }
  .cin.form-clearer {
    margin-bottom: 0.75rem;
  }
  .date-container > .field {
    width: calc(100% - 2rem);
  }
}

.price-increase-container {
  .ffDHgC {
    .ui.input,
    .react-datepicker-wrapper,
    .ui.selection.dropdown {
      max-width: 100% !important;
    }
  }

  .ui.grid > .row {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
  }

  .ui.table.schedule-price-increase {
    .highlight,
    .td.highlight {
      background-color: $colour-dark-turquoise !important;
      border: none;
      color: $color-white;
      font-weight: bold;
      padding: 0px;
      text-align: center !important;
    }
    .grey-highlight {
      background-color: $color-grey-8 !important;
    }
    thead th.rounded-left-border {
      border-left: 1px solid $color-grey-1;
      border-top-left-radius: 5px;
    }
    tr td.empty {
      background-color: #fff !important;
      border: 0px !important;
      visibility: hidden;
    }

    th.empty,
    td.empty {
      border: 0px !important;
    }
    border-left: 0px !important;
    border-bottom: 0px !important;
    tr td.border-left {
      border-left: 1px solid $color-grey-1 !important;
    }
    tbody,
    tfoot {
      tr:last-child {
        td.empty-footer {
          background-color: $color-white !important;
          border: 0px !important;
          visibility: hidden;
        }
        td.highlight-footer {
          background-color: $color-grey-8 !important;
          border-right:  1px solid $color-grey-1 !important;
          font-weight: bold;
        }
        td.footer-strong-bdr-top {
          border-top: 3px solid $colour-black !important;
          visibility: visible;
        }
      }
    }

    tfoot {
      tr.highlight td {
        border-top: 1px solid $color-grey-1;
        background-color: $colour-white-turquoise;
        border-bottom: 1px solid $color-grey-1;
        border-right: 1px solid $color-grey-1;
        font-size: 12px;
        font-weight: bold;
        text-align: center;
        color: $colour-black;
      }
      tr.no-left-border td {
        border-left: 0px !important;
      }
    }

    span.highlight {
      background-color: $color-white !important;
      color: $colour-black;
      padding: 0.5em;
      border: 1px solid $color-grey-1;
      width: 100% !important;
      display: block;
    }
  }

  .ui.table {
    &.current-charges,
    &.future-charges {
      border-left: 0px !important;
      border-bottom: 0px !important;
    }
    &.qtr-services {
      thead th:first-child {
        border-top-left-radius: 5px;
        border-left: 1px solid $color-grey-1 !important;
      }
    }
    &.cut-sheet-table {
      thead th:first-child {
        border-top-left-radius: 5px;
        border-left: 1px solid $color-grey-1 !important;
      }
    }
    &.historic-cut-sheet-table,
    &.historic-qtr-services-table,
    &.total-charges-table,
    &.metered-services-table {
      thead:nth-child(2) {
        th:last-child {
          border-right: 3px solid $colour-black !important;
          border-radius: 0px;
        }
      }
    }

    &.historic-cut-sheet-table,
    &.historic-qtr-services-table {
      tbody {
        tr:last-child {
        
          td {
            border-bottom: 3px solid $colour-black !important;
          }
        }
      }
      tfoot {
        tr:nth-child(2) {
          td {
            background-color: $color-grey-8 !important;
            border-top: 3px solid $colour-black;
            border-bottom: 3px solid $colour-black;
            font-weight: bold;
            text-align: center;
            font-size: 12px;
          }
        }
      }
    }
  }

  .strong-bdr-left {
    border-left: 3px solid $colour-black !important;
  }
  .strong-bdr-right {
    border-right: 3px solid $colour-black !important;
  }
  .strong-bdr-top {
    border-top: 3px solid $colour-black !important;
  }
  .strong-bdr-bot {
    border-bottom: 3px solid $colour-black !important;
  }
}
