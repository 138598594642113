div.ready-to-invoice-list {
    padding: 0.5rem;
    h4 {
      margin-left: 2em;
      margin-bottom: 10px;
    }
    .search-filter-display {
      .ui.form {
        display: flex;
      }
      .invoices-list-filter {
        display: flex;
        align-items: center;
        .field {      
          margin-top:0;
          margin-bottom:0;
          &:not(:first-child) {
            margin-left: 1rem;
            display: flex;
            .react-datepicker-wrapper {
              width: 8em !important;
              margin-left: 0.25em;
            }
          }
        }
        .action-button {
            border-radius: 5px;
            padding: 0 0.5em;
            margin-left: 2rem !important;
            margin-left: 0.5rem;
            cursor: pointer;
            background: transparent;
            color: $colour-standard-turquoise;
            font-size: 16px;
            line-height: 1.75rem;
            &:hover {
                color: lighten($colour-standard-turquoise, 10%);
            }
            &.selected {
                background-color: $colour-standard-turquoise;
                color: $colour-white;
            }
        }
      }
    }
    table {
      thead {
        th {
          padding-left: 0.1%;
          font-size: 16px;
        }
      }
      tbody {
        td {
          font-size: 16px;
          padding-bottom: 0.5em !important; //otherwise the ellipsis menu forces the entire row down
          padding-top: 0.5em !important;
          i.icon {
            font-size: 2em;
            color: $colour-light-blue;
            padding-top: 0.2em;
          }        
        }
      }
    }

    .create-invoice-action {
        margin-left: auto;
    }
}  

  