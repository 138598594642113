.defaults-page-title {
  justify-content: space-between;
  .title-bar {
    align-items: center;
    flex-wrap: wrap;
  }

  @include breakpoint-sm {
    margin-bottom: 0.5rem;
  }

  div.cin.loadable.fit-loader {
    width: initial!important;   //because we've got really specific width overrides in `global.scss`
  }

  form {
    .ui.checkbox label {
      > span:not(:last-child) {
        margin-right: 0.5em;
      }
    }
  }
}

.content-container {
  padding: 0.5rem;

  .ui.grid {
    margin: 0rem !important;
    padding: 0 !important;
  }

  .layout-grid {
    flex-wrap: wrap;

    .section-1 {
      width: 35%;
      min-width: 30em;
    }

    .section-2 {
      width: 33%;
      min-width: 25em;
      .markups-top-margin {
        margin-top: 0em !important;
        padding-top: 0em;
      }
    }

    .section-3 {
      width: 32%;
      min-width: 25em;
    }

    > .column {
      padding: 0 !important;
    }

    .field {
      .ui.selection.dropdown {
        .text {
          min-height: 1.1em !important;
        }
      }
    }

    .ui.grid,
    .ui.grid > * {
      margin: 0 !important;
      padding: 0 !important;
    }

    .ui.grid > .row {
      margin-top: 0.25rem !important;
      > .column:first-child {
        padding-left: 0 !important;
      }
      &.ream-override-margin {
        margin-top: 0em !important ;
        margin-bottom: 0.25em !important;
      }
    }

    .field {
      margin-bottom: 0.5rem;
    }

    .section.paper-weights {
      > div:last-child {
        margin-left: 2rem !important;
      }
      margin-bottom: 4.15em;
    }

    .min-paper-costs {
      max-width: 28rem;
    }

    .section {
      margin-bottom: 1rem;
    }

    .paper-cost-header > .column {
      font-weight: bold;
    }
  }
}

.cut-sheet-paper-list{
  .ui.selection.dropdown {
    border: 1px solid $colour-standard-turquoise !important;
    padding: 0.3em!important;
    margin-right: 1em;
    width: 10em !important;
  }
  .cin.action-bar .action-bar-item{
    padding: 0.5em;
  }
  .cin.action-bar .action-bar-item:last-of-type{
    padding-top: 0.25em;
  }
  @include customBreakpoint("max-width: 991px") {
    .cin.action-bar{
      display: inline;
    }
  }
  tr td:nth-child(2){
    text-align: left!important;
  }
 
  .table{
     tr{
      th{
        font-size: 12px !important;
        min-width: 16em;
        background-color: $colour-white-turquoise !important;
      }
    }
    tbody{
      td{
        font-size: 12px;
        text-align: center !important;
      }
    }
    .highlight{
      min-width: 0.2em;
      color: white;
      padding: 0em 0.8em 0em 0.8em !important;
      &.blue{
        background-color: $colour-dark-turquoise !important;      
      }
      &.grey{
        background-color: $colour-grey !important;      
      }
    }
    .top-bar-large-format{
      height: 0.5em;
    }
    .lf-service-list{
      min-width: 12em !important;
      &.short{
        min-width: 7em !important;
      }
      &.long{
        min-width: 15em !important;
      }
    }
  }
}
