h1,
h2,
h3,
h4,
h5,
p,
small,
.field-text,
label {
  color: $text-color;
}

h1,
h2,
h3,
h4,
h5,
div.header {
  font-family: "Barlow", "Helvetica Neue", Arial, Helvetica, sans-serif !important;
}

h1,
h2,
h3,
h4 {
  font-weight: bold;
}

p {
  font-size: 16px;
}
small {
  font-size: 12px;
}

.field-text {
  font-size: 16px;
  font-weight: normal;
}

.placeholder {
  font-size: 16px;
  color: #ccc;
}

textarea {
  font-family: $font-name, "Helvetica Neue", Arial, Helvetica, sans-serif;
}


.red-text {
  color: $color-red;
}