.system-configs-list {
  .system-config-item {
    display: flex;
    margin-bottom: 0.75rem;
    align-items: flex-end;

    .field {
      margin-bottom: 0;
    }

    .icon.help.circle {
      margin: 0 1em 2.35em -1.25em;
    }

    div.label {
      display: flex;
      justify-content: space-between;
    }

    div.read-only {
      flex-wrap: nowrap;
      overflow: auto;
      min-width: 23rem;
      p {
        white-space: nowrap;
        font-weight: bold;
      }
    }

    button.ui.button {
      font-size: 12px;
      margin-left: 1rem;
      padding: 0.5em;
    }
  }
}

.ui.button.maintenance-period-button {
  margin: 2em 0;
}
