.cfa-container{
    padding: 0px 10px 10px 10px ;
    max-width: 130em;
    margin: auto;
    .ui.menu{
        width: 26.8em;
        margin: 1em auto ;
        .item{
            font-size: 0.9em;
        }
        .active.item{
            background-color: $colour-white-turquoise;
        }
    }

}

.cin.side-panel.cfa-list-filter-side-panel {
    // Add space between 2 items on the same row
    .ui.grid .row .eight.wide.column {
        &:first-child > .field {
            padding-right: 0.5em;
        }
        &:last-child > .field { 
            padding-left: 0.5em;
        }
    }
    
    div.field > .ui.dropdown,
    div.field > .cin.base-picker > div.react-datepicker-wrapper {
        width: 100% !important;
    }
}

