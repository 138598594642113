.schedule-services-container {
  .ui.icon, .ui.icon:hover, .icon.focus {
    color: $color-red;
    background: transparent;
    padding: 0px;
  }
  .ui.icon.unlocked-button {
    color: green;
  }

  .ui.toggle.checkbox input:checked ~ .box:before,
  .ui.toggle.checkbox input:checked ~ label:before {
    background-color: $color-green !important;
  }

  .ui.table{
    border-bottom: none !important;
    border-left: none !important;
           
    td.border-left, td:first-child, th:first-child{
      border-left: 1px solid $color-grey-1 !important;
    }
    
    td.highlight{
      border: 3px solid $color-grey-2 !important;
      padding: 5px;
      background-color: $colour-white !important;
    }
    td.empty
    {
      border-right: none !important;
      border-left: none !important;
    }     
    .field{
      padding: 0px;
      margin: 0px;
    } 
  }
  .alternating-purple{
    .ui.table{
      tr {
          &:nth-child(odd) {
            background: $color-lighter-purple !important;
          }
        }
    }
  }
}

