.edocument-settings {
  margin-bottom: 2em;

  .ui.form {
    min-width: 32em;

    .field {
      margin-bottom: 0.5em;
      label {
        width: 17em;
      }
    }
  }

  .email-settings {
    .field {
      label {
        margin-bottom: 0 !important;
      }
    }
  }

  .checkbox-container {
    display: flex;
    align-items: center;

    .checkbox-label {
      display: flex;
      align-items: center;

      .checkbox-text {
        font-weight: bold;
        margin-right: 1em;
        //styles to make identical to form fields
        color: rgba(0, 0, 0, 0.87);
      }
    }
  }
}
