.defaults-grid.volume-selection-restrictions {
  .inline.field label,
  .data-field .field {
    width: 17rem;
    margin-right: 0.5rem !important;
    padding: 5px;
    border-right: 1px solid;
    height: 2.25em;
  }

  .inline.field label {
    white-space: nowrap;
    overflow: visible;
  }

  .data-field {
    display: flex;
    margin-top: 0.5em;
    font-size: 16px;
    .field {
      font-weight: bold;
    }
  }

  .dealer-office-volume-selection {
    label {
      text-align: right;
      color: $colour-light-purple !important;
    }
  }
  .background-blue {
    background-color: rgb(162, 196, 201);
  }

  .inline.field {
    border: 1px solid;
    margin: -1px;
  }

  .vol-restriction-field {
    p {
      width: 12em;
    }
    input {
      width: 10em;
    }
  }

  .volume-selection-field {
    text-align-last: center;
    margin: 0 20px 0 20px;
  }

  .title {
    padding: 0 !important;
  }
  .volume-selection-restriction-edit-buttons {
    margin-bottom: 6px;
  }
}

.cost-per-device-box {
  margin: 0px 0px 5px 0px !important;
  border: solid black 1px;
  width: 26em;
  .row {
    .column {
      width: auto !important;
      align-self: center;
    }
  }
  .cost-per-device-column {
    padding: 0 1rem 0 0 !important;
  }
  .default-total-column {
    padding: 0 0 0 0 !important;
  }
}

.cost-per-device-title {
  .title {
    padding: 0 !important;
  }
}

.cost-per-device-edit {
  margin-bottom: 5px;
}

.disable-min-logic {
  .content.active {
    margin-bottom: 10px;
  }
  .disable-min-logic-edit {
    margin-bottom: 14px;
  }
  .disable-min-logic-box {
    border: solid black 1px;
    margin-left: 0em !important;
    margin-right: 0em !important;
    width: 32rem;
    .row {
      padding: 3px;
      margin-left: 5px;
    }
    .check-boxes {
      margin-left: 6rem !important;
      margin-bottom: 15px;
    }
    .ui.checkbox.read-only {
      max-width: 30em !important;
      label {
        cursor: not-allowed;
      }
    }
  }
}

.agreement-stability-settings {
  min-width: 85em;
  .agreement-stability-edit {
    margin-bottom: 5px;
  }
  .input {
    max-width: 5em;
    margin-right: 1em;
  }
  .row-padding-reducer {
    padding-bottom: 0 !important;
  }
  .agreement-stability-box {
    width: 85em;
    border: 1px black solid;
    .ui.checkbox.read-only {
      label {
        cursor: not-allowed;
      }
    }
    .agreement-stability-colored {
      margin-left: 2em;
      color: purple;
      .field {
        margin: 0 15px 0 15px;
      }
    }
    .grid {
      margin: 0px 0px 0px 10px !important;
    }
    .boxed {
      padding: 0px 0 0px 0 !important;
      margin-right: 1em;
      .row:nth-child(odd) {
        background-color: rgb(162, 196, 201);
      }
      .apply-if-difference-col {
        min-width: none;
        padding-left: 5px;
        &.option-text {
          .row {
            border-left: transparent;
            margin-left: -3rem;
            padding-top: 0.5rem;
          }
        }
        .row {
          border: 1px black solid;
          height: 2.5rem;
        }
        .checkbox-row {
          display: flex;
          padding-left: 1em;
          .checkbox {
            background-color: transparent !important;
          }
        }
        .apply-diff-text {
          align-self: center;
        }
      }
    }
    .no-margin-no-padding {
      margin-bottom: 1em !important;
      .column {
        padding: 0 !important;
        margin: 0 !important;
        .field div:nth-child(odd) {
          background-color: rgb(162, 196, 201);
        }
      }
    }
    .fixed-height {
      height: 2.5rem;
    }
    .checkbox-with-words {
      border-right: transparent !important;
      border: 1px black solid;
      max-width: 100% !important;
      width: 100%;
      margin: 0 !important;
      padding: 0.5em !important;
    }
    .checkbox-without-words {
      border: 1px black solid;
      max-width: 100% !important;
      width: 100%;
      margin: 0 !important;
      padding: 0.5em 0.5em 0.5em 43% !important;
    }
    .options-header {
      min-height: 2.5em;
      .column {
        border: 1px black solid;
      }
    }
  }
}

.settlement-parameters-settings {
  .settlement-parameters-edit {
    margin-bottom: 5px;
  }

  .settlement-parameters-box {
    margin: 0px 0px 5px 0px !important;
    border: solid black 1px;
    width: 32em;
    .title {
      font-weight: bold;
      justify-content: right;
      margin: 1em 2em 0 0;
    }

    .settlement-parameters-data {
      margin: 1em;
      border: solid black 1px;
      label {
        width: 15em !important;
      }
      .field:nth-child(even) {
        background-color: rgb(162, 196, 201);
      }
      .field {
        display: flex;
        justify-content: space-between;
        border-bottom: solid 1px black;
        padding: 0.5em;
        margin: 0;
        max-height: 2.7em;
        div {
          float: right;
        }
        .input {
          input {
            text-align: center;
          }
          float: right;
          margin-right: 3em;
          width: 8em !important;
        }
        .read-only {
          width: 15em;
          justify-content: center;
        }
      }
    }
  }
}

.contract-pre-approval {
    border: solid grey .5px;
    margin-left: 0em !important;
    margin-right: 0em !important;
    margin-top: .5em !important;
    width: 32rem;
    padding: 1em !important;
    .row {
      padding: 3px !important;
      margin-left: 5px;      
      .field{
        display: contents;
        label{
          margin-right: 1em;
          margin-top: 3px;          
        }
      }
    }   
    .ui.checkbox.read-only {      
      label {
        cursor: not-allowed;
      }
    }
}
