.cin.nav-menu.main-menu {
  background-color: $colour-white;
  .menu {
    .nav-menu-item {
      background-color: $colour-white;
      color: $nav-foreground-colour;
      font-size: 14px;
      font-weight: 700;
      padding-left: 0.5em;
      padding-right: 0.5em;
      &.logo {
        padding: 0.25em 3em 0.25em 1em;
        &:hover {
          background-color: $colour-white;
        }
        > img {
          image-orientation: from-image;
          height: 2.5em;
        }
      }
      &.active {
        background-color: $nav-active-colour;
        color: $colour-white;
      }
      &:hover:not(.active):not(.logo) {
        background-color: $nav-hover-colour;
      }
    }
    .popup-menu {
      font-weight: 600;
      display: flex;

      .nav-menu-item {
        display: flex;
      }

      &.account {
        flex-direction: column;
        justify-content: center;
        margin-right: 3rem;
        .account-icon-trigger {
          display: flex;
          align-items: center;
          .user-info {
            display: flex;
            flex-direction: column;
            margin-right: 0.5em;
            text-align: right;
            label:first-of-type {
              color: $nav-foreground-colour;
            }
            label:last-of-type {
              font-size: 11px;
            }
          }
        }
      }

      .account-icon {
        width: 2rem;
        height: 2rem;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 50%;
        font-size: 2.25rem;
        &::before {
          position: relative;
          left: 0;
          top: 0.25rem;
        }
      }

      & img.account-icon {
        height: 2rem;
        width: 2rem;
        border-radius: 100%;
      }
    }
  }
}
div.ui.popup.main-nav-popup {
  padding-left: 0;
  padding-right: 0;
}
.popup-menu-content {
  .header {
    font-size: 18px;
  }
  .nav-menu-item {
    display: block;
    font-size: 16px;
    font-weight: 600;
    padding: 0.25em 1.5em;
  }
  &.settings {
    .cogs {
      display: inline;
      margin-right: 0.5em;
    }
    .header-container {
      margin-bottom: 0.5em;
    }
  }
  &.main-nav-submenu {
    color: $colour-standard-turquoise;
    a.nav-menu-item {
      padding: 0.75em 2em;
      &.active {
        color: $colour-white;
        background-color: $colour-standard-turquoise;
      }
      &:hover:not(.active):not(.logo) {
        color: inherit;
        background-color: $nav-hover-colour;
      }
    }
  }
}
.top-menu-container {
  background-color: $colour-white;
  display: flex;
  justify-content: space-between;
  padding: 0.5em;
  .right-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    .sidebar.icon,
    .close.icon {
      font-size: 2em;
      padding-top: 0.1em;
      color: $colour-purple;
    }
  }
  a.logo {
    img {
      image-orientation: from-image;
      height: 4.5em;
    }
  }
}
.ui.segment.pushable {
  border: none;
}
.nav-group {
  width: 20rem;
  position: relative;
  &.has-icon {
    padding-left: 2rem;

    img {
      position: absolute;
      top: 5px;
      left: 0;
    }
  }
  .nav-group-title {
    margin-top: 0;
    margin-bottom: 0;
    padding-bottom: 0.5rem;
    font-size: 18px;
  }
  & ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    & > li {
      &:first-child {
        width: 40%;
      }
      width: 60%;
      margin-bottom: 0.5rem;
      font-size: 16px;
      color: $colour-blue;
      &.full-width-nav-item {
        width: 100%;
      }
      & .popup-nav-btn.ui.button {
        color: $colour-blue;
      }
    }
    &.single-col > li {
      width: 100%;
    }
  }
}

// NavMenu - mobile styling

.mobile-wrapper {
  flex-grow: 1;
  > .pushable {
    background: transparent;    
  }
  .pushable, .pusher {
    overflow: visible;
  }
}

.ui.segment.sidebar.mobile-main-menu-segment {
  padding: 0;
  border: 0;
}

.mobile-main-menu.cin.nav-menu > .ui.menu {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  padding-bottom: 1rem;
  overflow-x: hidden;
  &:after {
    content: none;
  }
  & .item.cin.nav-menu-item {
    padding: 0.75rem !important;
    height: auto;
    line-height: normal;
    font-weight: bold;
    color: $primary-color;
    border-radius: 0 !important;
    width: 100%;
    text-align: center;
    &:hover {
      background-color: $nav-hover-colour;
    }
    &.active {
      background-color: $nav-active-colour;
      color: #ffffff;
      & h5 {
        color: $orange;
      }
    }
    & h5:hover {
      color: $orange;
    }
  }
  // dropdown menus in mobile menu styling (submenus)
  & .item.cin.nav-menu-submenu {
    &.visible {
      padding-bottom: 0 !important;
    }
    > i.dropdown.icon {
      display: none;
    }
    > .menu.visible {
      margin-top: 0.75rem;
      position: relative;
      left: -0.75rem;
      width: calc(100% + 1.56rem);
      border: none;
      box-shadow: 0 0 0 0;
      border: none;
      > .item[role="option"] {
        text-align: center;
        background-color: $colour-standard-turquoise-dark !important;
        color: $colour-white !important;
        font-weight: 700 !important;
        width: 70%;
        margin: auto;
        &:hover {
          background-color: $colour-light-blue-hover !important;
        }
        &.active {
          background-color: $colour-light-blue !important;
          color: #ffffff !important;
        }
        &:last-child {
          margin-bottom: -1px; // fix random issue where parent maintains <1px margin
        }
      }
    }

    & i.angle.icon {
      margin-right: -0.785rem;
    }
  }

  & .user-dropdown {
    & > .dropdown.icon {
      display: none;
    }
    & > .menu {
      border: 2px solid $orange;
      &:after {
        border-top: 2px solid $orange;
        border-left: 2px solid $orange;
        top: -5px;
        box-shadow: none;
      }
      & .item {
        color: $text-color !important;
        &:hover {
          background: $colour-light-grey !important;
        }
      }
    }
  }
}

.cin.nav-menu.secondary {
  .menu.secondary.vertical {
    .cin.nav-menu-item {
      background-color: $colour-white;
      color: $colour-standard-turquoise;
      font-size: 14px;
      font-weight: 700;
      cursor: pointer;

      &.active {
        color: $colour-white;
        background-color: $colour-standard-turquoise;
      }
    }
  }
}

.breadcrumb-bar {
  height: 2.1rem;
  background-color: $nav-border-colour;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
  font-weight: bold;
  a {
    color: $text-color;
  }

  &::before {
    content: "";
    height: 2.1rem;
    background-color: #d9f1f1;
    width: 100%;
    position: absolute;
    right: 0;
    z-index: -1;
  }

  .view-as {
    .text {
      font-weight: normal;
    }
    .action {
      color: $nav-foreground-colour;
    }
  }
}

.top-menu-container,
.nav-menu.main-menu {
  .site-header-container {
    > img {
      image-orientation: from-image;
      height: 2.5em;
      margin-top: 0.1em;
    }
    .info, .info * {
      color: $text-color;
      font-weight: normal;
      cursor: pointer;
    }
    .info {
      padding-top: 0.5rem;
      .office, .time {
        padding: 0.1rem 0;
      }
      .office, .office label {
        font-size: 15px;
      }
      .office {
        display: flex;
        .icon-wrapper {
          width: 1.5rem;
        }
      }
      .time {
        font-size: 13px;
        padding-left: 1.5rem;
        .cin.time-formatter {
          padding-left: 0.75em;
        }
      }
    }
  }
}