body,
#root {
  background-color: $background-color;
  height: 100%;
  width: 100%;
}

button.ui.primary.button {
  color: white;
  background-color: $colour-standard-turquoise;
  font-size: 17px;
  padding: 0.5em;
  &.icon {
    display: flex;
    i,
    img {
      padding-right: 0.5em;
      padding-left: 0.3em;
    }
  }
  &:hover {
    background-color: lighten($colour-standard-turquoise, 3%) !important;
  }
  &:focus {
    background-color: $colour-standard-turquoise;
  }
}

.blue {
  button.ui.ui.primary.button {
    background-color: $color-standard-blue;
    &:hover {
      background-color: lighten($color-standard-blue, 10%) !important;
    }
    &.cancel-title-action {
      background-color: $colour-white;
    }
  }
}

.yellow {
  button.ui.ui.primary.button {
    background-color: $color-dark-yellow;
    &:hover {
      background-color: lighten($color-dark-yellow, 10%) !important;
    }
    &.cancel-title-action {
      background-color: $colour-white;
    }
  }
}

.violet {
  button.ui.ui.primary.button {
    background-color: $colour-violet;
    &:hover {
      background-color: lighten($colour-violet, 10%) !important;
    }
    &.cancel-title-action {
      background-color: $colour-white;
    }
  }
}

.add-modal {
  display: flex;
  div.step {
    &.details {
      > div.field {
        display: inline-block;
        vertical-align: top;
        width: 50%;
        padding-right: 2em;
        label {
          font-size: revert;
          font-weight: 500;
        }
        .checkbox {
          margin-top: 2.25em !important;
        }
      }
    }
  }

  &.ui.modal.visible > div.header {
    border-top: 5px solid $colour-violet !important;
  }

  &.ui.modal .header {
    background-color: $colour-light-violet !important;
  }
}

.flex {
  display: flex;
  &.start {
    justify-content: start;
    > :only-child {
      margin-left: 0;
    }
  }
  &.end {
    justify-content: end;
    > :only-child {
      margin-right: 0;
    }
  }
  &.space-between {
    justify-content: space-between;
  }
  &.align-center {
    align-items: center;
  }
}

// typeAheadlookup in headers
.header-dropdown {
  display: inline-block;
  max-width: 50rem;
  > div.ui.dropdown,
  > div.ui.dropdown div.text {
    min-width: 15rem;
  }
  input {
    font-family: $font-name, "Helvetica Neue", Arial, Helvetica, sans-serif;
    line-height: 1.75rem;
    font-weight: bold;
  }
}

.environment-banner {
  background-color: $red;
  border: 1px solid white;
  color: white;
  display: block;
  font-size: 20px;
  font-weight: 700;
  height: 36px;
  line-height: 30px;
  opacity: 0.6;
  position: fixed;
  left: -130px;
  text-align: center;
  bottom: 50px;
  transform: rotate(45deg);
  width: 400px;
  z-index: 10000;
  pointer-events: none;
}
.load-more-button {
  width: 100%;

  > .ui.button {
    display: block;
    margin: 0 auto;

    &.disabled,
    &:disabled {
      opacity: 1 !important;
    }
  }
}
div.shell > section {
  display: flex;
  flex-direction: column;
  height: 100%;
}
div.content-panel,
div.content-container {
  background: $colour-white;
  border-top: 5px solid $colour-standard-turquoise !important;
  border-radius: 5px;
  box-shadow: 5px 5px 22px #0000001a;
  padding-top: 0.5em !important;
  padding-bottom: 0.5em;
  &.blue {
    border-top: 5px solid $color-standard-blue !important;
  }
  &.yellow {
    border-top: 5px solid $color-dark-yellow !important;
  }
  &.violet {
    border-top: 5px solid $colour-violet !important;
  }
  .ui.table {
    border: none;
    border-bottom: 1px solid rgba($colour-light-grey, 0.5);
    margin-top: 0;
    thead {
      border: none;
      > tr > th:first-child {
        padding-left: 2em;
      }
      th {
        background: $colour-white;
        font-weight: bold;
        font-size: 16px;
        padding-top: 0.25rem;
        padding-bottom: 0.25em;
      }
    }
    tbody {
      tr {
        transition: background 0.25s ease;
        td:first-child {
          padding-left: 2em;
        }
        td {
          border-top-color: transparent;
        }
        > td.right.aligned.hoverable {
          > .ui.item.dropdown > i {
            font-size: 2em;
            &::before {
              top: 0.1em;
              position: relative;
            }
          }
        }
        &:nth-child(even) {
          background: $table-alternate-row-grey;
        }
        &:hover {
          background: $colour-lighter-blue !important;
        }
      }
    }
  }
}
div.ui.pagination.menu {
  border: none;
  box-shadow: none;
  min-height: 1.5rem;
  background: transparent;
  margin: 0 auto;
  & .item {
    color: $text-color;
    border: none;
    min-width: 0.3rem;
    padding: 0 0.5rem !important;
    border-radius: 2px;
    transition: none;
    &:before {
      content: none;
    }
    &.active {
      background-color: $colour-standard-turquoise;
      color: #ffffff;
      font-weight: bold;
    }
  }
}

.blue .ui.pagination.menu .item.active {
  background-color: $color-standard-blue !important;
}

footer.table-pagination {
  display: flex;
  label.results-counter {
    color: $colour-grey;
    font-size: 0.8em;
  }
  a[type="nextItem"] {
    > img {
      transform: rotate(180deg);
    }
  }
}
.notification-stack {
  z-index: 50;
}
div.ui.modal.custom-actions {
  div.actions {
    //hide the default action container
    display: none !important;
    * {
      display: inherit !important;
    }
  }
  > div.content {
    padding: 0;
    //*** stolen from Semantic to match the default action container
    border-bottom-left-radius: 0.28571429rem;
    border-bottom-right-radius: 0.28571429rem;
    //***
    > div.dimmable {
      > *:not(div.dimmer) {
        padding: 1rem 1.5rem;
      }
    }
  }
  div.custom-action-content {
    padding: 1rem 1.5rem;
    button {
      display: inline-block;
    }
    :not(:last-child) {
      padding-bottom: 0.75rem;
    }
    div.custom-action-footer {
      border-top: 1px solid $colour-translucent-dark-grey;
      margin-left: -1.5rem;
      margin-right: -1.5rem;
      padding: 0.75rem 1.5rem 0;
    }
  }
}

.cin.page-content {
  // Breadcrumb bar is inside PageContent to ensure they align.
  .column {
    margin-top: 0 !important;
  }
  .breadcrumb-bar {
    margin-bottom: 2rem;
  }
}

.principal-list {
  //style for primary buttons w/ icon
  & .ui.icon.primary.button {
    display: flex;
    & img {
      padding-right: 0.5em;
    }
  }

  .cin.action-bar-item.right {
    > .ui.primary.button {
      margin-right: 1.5em;
      margin-left: 0;
    }

    @include breakpoint-sm {
      padding-left: 2rem;
    }
  }

  & .cin.action-bar-item.fluid {
    padding-left: 2em;
  }

  .action-bar-div {
    display: flex;
    margin-bottom: 0;
    margin-top: -3rem;
    margin-right: -1rem;
    align-items: center;
    flex-wrap: wrap;
    float: right;
  }
}

.icon-only-button {
  background-color: unset !important;
  padding: 0em !important;
  > img.ui.image {
    max-width: initial;
  }
}

.cin.side-column {
  > .column {
    padding-right: 0.25em !important;
    padding-left: 0.75rem;
    padding-top: 0.5rem;
    margin-top: 0.5rem;
    width: 12rem;

    @include breakpoint-sm {
      width: 0;
      padding: 0 !important;
      margin: 0;
      .cin.nav-menu {
        display: none !important;
      }
    }
  }
  .content {
    .cin.page-content {
      max-width: 100% !important;
      margin-left: 0 !important;
      margin-top: 1rem;
    }
    padding-left: 0.25em !important;
    @include breakpoint-sm {
      width: 100%;
      margin-top: 2rem;
    }
  }
}

.horizontal-scrollbar {
  overflow-x: auto;
  overflow-y: auto;
  padding-bottom: 10px;
  display: flex;
  position: relative;
}

.vertical-scrollbar {
  overflow-y: auto;
  &.small {
    max-height: 170px;
  }
  &.small-medium {
    max-height: 220px;
  }
  &.medium {
    max-height: 250px;
  }
  &.large {
    max-height: 400px;
  }
  .ui.table thead tr:first-child > th {
    position: sticky !important;
    top: 0px;
    z-index: 1;
  }
}

.horizontal-scrollbar-padded {
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  padding: 25px;
  margin: -25px;
}

@media (max-width: 1000px) {
  .horizontal-scrollbar-1000 {
    overflow-x: auto;
    overflow-y: hidden;
    display: flex;
    position: relative;
  }
}

@media (max-width: 800px) {
  .horizontal-scrollbar-800 {
    overflow-x: auto;
    overflow-y: hidden;
    display: flex;
    position: relative;
    min-width: 790px;
  }
}

div.content-panel {
  .purple-highlight {
    background-color: #d9d3ea;
  }
  .green-highlight {
    background-color: #daebd3;
  }
  .blue-highlight {
    background-color: #cfe2fb;
  }
  .yellow-highlight {
    background-color: $color-light-yellow;
  }
  .red-highlight {
    background-color: red;
  }

  .blue {
    color: $colour-hyperlink-blue;
  }
  .ui.button {
    &.amber {
      background-color: $color-yellow;
    }
    &.red {
      background-color: $color-red;
    }
  }
  .empty-container {
    width: 100%;
    height: 90px;
  }
  .spaced {
    padding: 20px 20px;
    span {
      font-size: 12px;
    }
    button span {
      font-size: 1em;
    }
    span.indent {
      margin-left: 2em;
      font-weight: bold;
      font-size: 14px;
    }

    .ui.labeled.input {
      width: 100% !important;
      font-size: 0.9em !important;
    }
    .ui.labeled p {
      font-size: 1em;
    }
    .ui.form .ui.input > .ui.label:not(.currency-label) {
      width: 25%;
      padding: 0.6em !important;
    }

    .custom-sublabel {
      margin-bottom: 14px;
      .field {
        width: 100%;
        border: 1px solid rgba(34, 36, 38, 0.15);
      }
      textarea {
        width: 100% !important;
        padding-top: 10px !important;
        border: none;
      }
      .ui.selection.dropdown,
      .ui.input input {
        width: 100% !important;
        padding-top: 0.5em !important;
        border: none;
        i.dropdown.icon {
          padding-top: 10px;
        }
      }
    }

    .left {
      float: left;
    }
    .right {
      text-align: right;
      float: right;
    }
    .column {
      margin-top: -0.6em !important;
    }
    .left-centered {
      float: left;
      font-size: 10px;
      margin-left: 5em;
    }
    span.right {
      text-align: right;
      float: right;
    }
  }

  .warning {
    color: $color-red;
  }

  .widget-tile {
    margin: 0.2em 0.2em 0.2em 0;
    border-radius: 5px;
    border-left: 5px solid $colour-standard-turquoise;
    box-shadow: 2px 2px 10px $colour-grey;
    transition:
      box-shadow 0.2s ease,
      filter 0.2s ease;
  }

  .widget-tile.center {
    border-top: 5px solid $colour-standard-turquoise;
    border-left: none;
  }
  .highlight {
    background-color: $color-turquoise-highlight;
  }

  .highlight-page-title {
    background-color: $colour-white-turquoise;
    padding: 10px 20px 10px 20px;
    margin-top: -7px;
  }

  .centered-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    font-size: 12px;
  }
}

td.red,
.red td,
.red td a,
.red a,
.red {
  color: $color-red;
}
.green {
  color: $color-green;
}
.grey {
  color: $colour-grey;
}
.orange {
  color: $color-orange;
}

hr.highlight {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid $colour-white-turquoise;
  margin: 1em 2em;
  padding: 0;
  clear: both;
}

hr.highlight {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #d9f1f1;
  margin: 1em 2em;
  padding: 0;
}

hr.highlight.wide {
  margin: 0.5em 0em;
  padding: 0;
  clear: both;
}

.center {
  text-align: center;
}

.clear {
  clear: both;
  width: 100%;
  height: 1px;
}

.right-align,
.right-align td,
.right-align th {
  text-align: right !important;
}

.left-align,
.left-align td,
.left-align th,
td.left-align {
  text-align: left !important;
}
.centre-align,
.centre-align td,
.centre-align th,
td.centre-align {
  text-align: center !important;
}
.ui.dropdown .left.menu {
  z-index: 2006;
  overflow: visible;
}

.padded {
  padding: 10px 15px;
  .right {
    float: right;
  }
}

.simple-border {
  border: 1px solid $color-grey-1;
  border-radius: 5px;
}

.ui.modal .header {
  font-family: "Lato", "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-size: 1.4em;
  background-color: $colour-white-turquoise;
  padding-bottom: 10px !important;
  font-weight: bold !important;
}

.blue.ui.modal .header {
  background-color: $color-standard-light-blue !important;
}

div.ui.modal.visible > div.header {
  border-top: 4px solid $colour-standard-turquoise !important;
}

div.ui.modal.visible.blue > div.header {
  border-top: 4px solid $color-standard-blue !important;
}

div.ui.modal.visible.yellow > div.header {
  border-top: 4px solid $color-dark-yellow !important;
  background-color: $color-lighter-yellow !important;
}

.table-config-modal-header {
  display: flex;
  justify-content: space-between;
}

.float-left {
  float: left;
  padding-left: 5px;
}

.full-width {
  width: 100%;
}
.modal.outline-grid {
  .ui.table thead tr th {
    background-color: $colour-white-turquoise;
    &:not(:first-of-type) {
      border-left: 1px solid $color-grey-1;
    }
  }
  .ui.table tbody tr:nth-child(even) {
    background: $table-alternate-row-grey;
  }

  .ui.table tbody tr td:not(:first-of-type) {
    border-left: 1px solid $color-grey-1;
  }
  .ui.table {
    td,
    th {
      text-align: center;
    }
  }
}

div.schedules-list,
div.products-list,
div.customers-list,
div.suppliers-list,
div.cfa-list,
div.schedule-price-increase,
div.funder-list,
div.funders-list,
div.customer-billing-list {
  .settings-title {
    min-width: 7em;
  }
  .defaults-page-title {
    .filter-display,
    .cin.loadable.fit-loader {
      width: 100%;
      padding-left: 1em;
      margin-bottom: 0em;
    }
    .ui.form .field {
      margin-top: 0.3em !important;
    }
    @media (max-width: 768px) {
      .search-input input {
        max-width: 150px !important;
      }
      .ui.input.highlighted-input {
        width: 152px !important;
      }
    }
  }

  .cin.action-bar {
    font-size: 12px;
  }
  .search-filter {
    padding: 0.1em 0.3em;
  }
  table {
    thead {
      th {
        padding-left: 0.5%;
        padding-bottom: 1em !important;
        padding-top: 1em !important;
        font-size: 16px;
      }
    }
    tbody {
      td {
        font-size: 16px;
        padding-bottom: 0.1em !important;
        padding-top: 0.1em !important;
        i.icon {
          font-size: 1.4em;
          padding-top: 0.2em;
        }
        div.dropdown {
          div.item[role="option"] {
            font-size: 16px;
            font-weight: 700;
            color: $colour-standard-turquoise;
            &:hover {
              color: $colour-standard-turquoise;
              background-color: $colour-standard-turquoise-light;
            }
          }
        }
      }
    }
  }
}

.delete-icon-btn {
  border-radius: 2em !important;
  width: 2em !important;
  height: 2em !important;
  padding: 0.15em !important;
}

.cin.page-content.standard-page {
  max-width: 128em !important;
}

.align-text-left {
  text-align: left;
}

div.spv-list,
div.spvs-list {
  .settings-title {
    min-width: 7em;
  }
  .defaults-page-title {
    .filter-display,
    .cin.loadable.fit-loader {
      width: 100%;
      padding-left: 1em;
      margin-bottom: 0em;
    }
    .ui.form .field {
      margin-top: 0.3em !important;
    }
    @media (max-width: 768px) {
      .search-input input {
        max-width: 150px !important;
      }
      .ui.input.highlighted-input {
        width: 152px !important;
      }
    }
  }

  .cin.action-bar {
    font-size: 12px;
  }
  .search-filter {
    padding: 0.1em 0.3em;
  }
  table {
    thead {
      th {
        padding-left: 0.5%;
        padding-bottom: 1em !important;
        padding-top: 1em !important;
        font-size: 16px;
      }
    }
    tbody {
      td {
        font-size: 16px;
        padding-bottom: 0.1em !important;
        padding-top: 0.1em !important;

        div.dropdown {
          div.item[role="option"] {
            font-size: 16px;
            font-weight: 700;
            color: $colour-standard-turquoise;
            &:hover {
              color: $colour-standard-turquoise;
              background-color: $colour-standard-turquoise-light;
            }
          }
        }
      }
    }
  }
}

span.required {
  display: inline-block;
  vertical-align: top;
  margin: -0.2em 0em 0em 0.2em;
  color: #db2828;
}

.ui.message {
  p {
    white-space: pre-line !important;
  }
}

.temporary-rates {
  padding-bottom: 4rem;
  .rate-group {
    display: flex;
    .field {
      width: 40%;
    }
  }
  label {
    margin-right: 1em;
  }
  .temporary-rates-header {
    display: contents;
    .rate-actions {
      display: flex;
      margin-top: -3rem;
      float: right;
      .ui.dropdown {
        border-width: 0;
        border-style: none;
        border-color: #cccccc;
        padding: 0;
        border-radius: unset;
      }
    }
  }
}
