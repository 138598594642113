.customer-finance{
    .wizard-navigation{
        .ui.menu {
            width: 49.5em;
            margin: 1em auto;
        }
    }

    .additional-units.wizard-navigation{
        .ui.menu{
            width: 32.5em;
        }
    }

    .misc-invoicing, .schedule-credit-note, .minimum-billing, .to-be-billed, .invoice-search {
        
        h4.finance-title{
            padding-top: 0.8em;
            font-size: 1.6em!important;
        }
        .ui.form .field{
            margin-top: 0em !important;
            font-size: 14px;
        }
        .defaults-page-title .cin.loadable.fit-loader{
            margin-left: -1.8em!important;
        }
        .text-search-wrapper{
            margin: 1em 0em !important;
        }

        .ui.grid{
            margin: 0em;
            padding:0em;
        }
        .right-header-col{
            max-width: 34rem;
            padding-top: -0.5em;
            padding-bottom: 0.5em;
            clear: both;
            display: inline;
            .six.wide.column{
                padding-top:0.5em;
            }
            .row, .ui.grid > .doubling.row > .column, .ui.doubling.grid > .row > .column, .ten.wide.column{
                padding: 0.2em 0em !important;
                margin: 0em;
                font-weight: bold;
            }
            .column{
                padding-right: 0em!important;
            }
            .ui.input > input, .ui.selection.dropdown {
                border: 1px solid $colour-standard-turquoise !important;
                padding: 0.3em!important;
            }
        }

        .ui.form .react-datepicker-wrapper input[type=text] {
            border: 1px solid  $colour-standard-turquoise !important;
            padding: 0.3em!important;
            width: 11.3em;
        }
        .table-config-container{
            text-align: right;
        }
        .react-datepicker-popper{
            z-index: 11;
        }
        .footer{
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
        }
        .sml-screen{
            display:none;
        }
    
        @include customBreakpoint("max-width: 991px") {
            .cin.action-bar,  .sml-screen{
                display: inline;
            }
            .table-config-container{
                padding-top: 10em;
                text-align: left;
            }
            h4.finance-title{
                margin-top:-2em;
            }
        }
    }
   

}