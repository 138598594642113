.large-format-add-button {
  width: 14em;
  display: flex !important;
  background: white !important;
  > span {
    padding-left: 10px;
  }
}

.lf-list-columns {
  .min-width-7 {
    min-width: 7em;
  }
  .min-width-14 {
    min-width: 14em;
  }
}

.large-format-modal-list {
  .large-format-list-item {
    display: flex;
    min-height: 2em;
    font-weight: bold;

    .button {
      width: 1em;
      height: 1.5em;
    }

    .lf-col {
      padding-right: 5px;

      > input {
        width: 100% !important;
      }
    }
    .lf-col-0 {
      min-width: 1.5em !important;
    }
    .lf-col-1 {
      min-width: 8.25em !important;
      .dropdown {
        max-width: 8em !important;
      }
      div.width-dropdown-pair {
        display: flex;
        justify-content: space-evenly;
        > div.field,
        > span {
          display: inline-block;
          width: 7em !important;
          div.dropdown {
            max-width: 8.25em !important;
          }
        }
      }
    }
    .lf-col-2 {
      max-width: 9em !important;
      width: 100% !important;
    }
    .lf-col-3 {
      max-width: 10em;
      width: 100% !important;
    }
    .lf-col-4 {
      max-width: 9em !important;
      width: 100% !important;
    }
    .lf-col-large {
      min-width: 15.25em !important;
      .dropdown {
        max-width: 15em !important;
      }
    }
  }
}

.large-format-printer-modal {
  .form-row {
    display: flex;
    .extra-padding {
      padding-right: 20px;
    }

    .printer-dropdown {
      width: 14.25em;
      .dropdown {
        max-width: 14em !important;
      }
    }

    .input-small {
      max-width: 9.5em !important;
      padding-right: 1em;
    }

    .radio-label {
      font-weight: bold;
      text-align: center;
      margin: 0em;
      p {
        font-size: 1rem !important;
      }
    }

    .radio-group {
      text-align: center;
      justify-content: center;
      padding-right: 5px;
    }
  }
}

.extra-settings-panel-columns .principal-list {
  .paper-widths {
    min-width: 24em;
  }
  .cin.action-bar-item.right > .ui.primary.button {
    margin-top: -2.75em;
    margin-right: -0.5em;
    float: right;
    max-height: 2em;
  }
}

.extra-settings-panel-top {
  display: flex;
  flex-wrap: wrap;
}
.extra-settings-panel-columns {
  flex-grow: 1;
  flex-shrink: 1;
  margin-bottom: 2em;
  &.paper,
  &.printer {
    min-width: 60rem;
    max-width: 100%;
  }
  &.roll-width {
    min-width: 30rem;
    max-width: 50%;
  }
  > div.content-panel {
    &:not(:first-of-type) {
      margin-top: 1em;
    }
  }
}
.extra-settings-panel-columns h4 {
  font-size: 2em;
}
.extra-settings-panel-columns th {
  background-color: $colour-light-grey;
}
.extra-settings-panel-columns .principal-list {
  border: none !important;
  border-radius: 5px;
}

.extra-settings-panel-columns div.content-container {
  margin: 0.5em !important;
  box-shadow: none !important;
}

.lf-column-splitter {
  width: 2em;
  @media screen and (max-width: 1600px) {
    width: 0.5em;
  }
}
.lf-title {
  font-size: 20px;
}
div.modal {
  &.roll-width {
    form {
      > div.field {
        &:not(:first-of-type) {
          display: inline-block;
          width: 50%;
          vertical-align: top;
          @include breakpoint-md {
            width: 100%;
          }
        }
      }
    }
  }
}

.lfp, .cspi{
  .ffDHgC > .read-only {
    max-width: 100% !important;
}
}