/* Outstanding Schedule Breakdown Table Styling Begin */
.ui.table.osb {
  border-left: none !important;
  td:first-child {
    border-left: 1px solid $color-grey-1;
    font-weight: bold;
  }
  th:first-child {
    border-top: none !important;
    background-color: transparent !important;
  }

  tr:nth-child(odd) td:first-child {
    background-color: $colour-white-turquoise;
  }
  tr:last-child {
    border-right: 1px solid $colour-grey !important;
    td {
      background-color: $color-grey-1 !important;
      font-weight: bold !important;
      border-bottom: 1px solid $colour-grey;
      border-right: 1px solid $colour-grey !important;
    }
  }
  td:last-child {
    font-weight: bold !important;
  }
}
/* Outstanding Schedule Breakdown Table Styling End */

/* Liability & charges custom Table Styling Begin */

.ui.table {
  &.liability-meter-services,
  &.liability-finances,
  &.liability-quarterly-services,
  &.schedule-charges {
    border-left: none !important;
    th {
      text-align: center !important;
    }
    th.yellow-highlight {
      background-color: $color-light-yellow !important;
    }
    th.red-highlight {
      background-color: $color-red-light !important;
    }
    th.light-red-highlight {
      background-color: $color-red-lighter !important;
    }
    td {
      padding: 5px 5px;
    }
    td:first-child,
    th:first-child {
      border-left: 1px solid $color-grey-1;
    }
    td:first-child.empty,
    th:first-child.empty {
      border-left: none;
    }
    td.no-top-border {
      border-top: 0px;
    }
    td.no-right-border {
      border-right: none !important;
    }
    td.border-right {
      border-right: 1px solid $color-grey-1;
    }

    tr:last-child {
      td {
        font-weight: bold;
      }
      td.empty,
      th.empty {
        border-top-color: transparent !important;
      }
    }
    td.total {
      font-weight: bold;
      border-top: 1px solid $color-grey-1 !important;
    }
    .center-align {
      text-align: center !important;
    }
    td.yellow-highlight,
    td.highlight {
      border: none !important;
    }
  }
  &.liability-meter-services,
  &.liability-finances {
    tr:last-child {
      td {
        font-weight: bold;
        border-top: 1px solid $color-grey-1 !important;
      }
    }
  }

  .hide {
    display: none;
  }
  .show {
    display: inline;
  }
}

.ui.table.liability-finances {
  background-color: transparent;
  border-bottom: none !important;
  thead.tr {
    border: none !important;
  }
  thead:first-child th.empty {
    background-color: transparent !important;
  }
  .highlight,
  td.highlight {
    background-color: $colour-dark-turquoise !important;
    border: none !important;
    padding: 0px;
    text-align: center !important;
    span {
      color: $colour-white;
      font-weight: bold;
      padding: 10px;
    }
  }

  .slim td {
    padding: 3px;
  }
  .ap-dt {
    border-left: 1px solid $color-grey-1 !important;
  }
}

.ls-summary {
  .ui.table.liability-finances {
    margin-top: -40px !important;
  }
}
.ls-contract-settlement {
  .ui.table.liability-finances {
    margin-top: -20px !important;
    thead th.settlement-title {
      border-bottom: none;
    }
  }
  .ui.table.liability-quarterly-services {
    tr td:nth-child(9),
    tr th:nth-child(9) {
      border-right: none !important;
    }
    &.managed {
      tr td:nth-child(9) {
        border-right: 1px solid $color-grey-1 !important;
      }
      tr td.no-right-border {
        border-right: none !important;
      }
      tr td.border-right {
        border-right: 1px solid $color-grey-1 !important;
      }
    }
    tr td:nth-child(8),
    tr th:nth-child(8) {
      border-right: 1px solid $color-grey-1 !important;
    }
  }
}

.ui.table.liability-quarterly-services {
  thead th:nth-child(4).empty,
  thead th:nth-child(5).empty,
  thead th:nth-child(8),
  thead th:nth-child(11).empty,
  tr td:nth-child(1).empty,
  tr td:nth-child(4).empty,
  tr td:nth-child(5).empty,
  tr td:nth-child(8),
  tr td:nth-child(11),
  .empty-footer td {
    border-right: none !important;
  }
  tr.footer td:nth-child(2),
  tr.footer td:nth-child(6) {
    border-right: 1px solid $color-grey-1 !important;
  }
  button.ui.primary.button {
    color: $color-blue-alt;
    background-color: transparent;
    font-size: 1em;
    text-decoration: underline;
    padding: 0.5em;
    font-weight: normal;
    &:hover {
      text-decoration: none;
      background-color: transparent !important;
    }
  }
}

.liability-csb {
  th {
    text-align: center !important;
  }
  th,
  tr:last-child td {
    background-color: $color-light-yellow !important;
    font-weight: bold;
  }
}

/* Liability Table Styling End */
