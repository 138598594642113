.yield-bands-grid {
  margin-top: 1rem;
  max-width: 51em;
  @include breakpoint-sm {
    width: 100%;
  }

  $row-height: 2em;

  .content-container {
    padding: 0;
    .defaults-page-title {
      padding-left: 1rem;
      padding-right: 1rem;
      .ui.button {
        margin: 0.5em 0 0 0 !important;
      }
    }
  }

  .table-title {
    @include breakpoint-sm {
      width: 100%;
    }

    > div {
      padding-left: 0.5em;
      width: 9em;
    }
  }

  .yield-band-column {
    > .field {
      display: flex;
      justify-content: center;
      width: 100%;
      width: 7em;
      margin-bottom: 0 !important;
      &:first-child {
        font-weight: bold;
        > div:not(.read-only) > p {
          display: none;
        }
      }
      > .read-only {
        align-items: center;
      }

      > .ui.input {
        width: 100% !important;
        height: 1.813em;
      }
      &.error {
        display: block !important;
      }
    }
  }

  .table-title,
  .yield-band-column {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    > * {
      height: $row-height;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }

    div:nth-child(4) {
      height: 3rem !important;
    }

    div:nth-child(even) {
      background-color: $table-alternate-row-grey;
    }
  }

  .yields-content {
    display: flex;
    flex-wrap: nowrap;
    padding-bottom: 1em;
  }
}
