.content-container.principal-list.users-list {
  .cin.action-bar{
    margin-top: 5px;
  }
  .users-list-filter {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 1rem !important;
    > .field {
      margin: 0 1rem 0 1rem !important;

      .ui.radio.checkbox {
        margin: 0 0 0 1rem !important;
        > label {
          padding-left: 1.25rem;
        }
      }
      &:nth-child(2) {
        margin-left: 0 !important;
        margin-right: 0 !important;
      }
    }
  }

  .user-status-icon {
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 0.15rem;
    border-style: solid;
    border-width: 1px;

    &.verified {
      color: $color-green;
      background-color: $color-green;
    }
    &.not-verified {
      color: $color-yellow;
      background-color: $color-yellow;
    }
    &.disabled {
      color: $color-red;
      background-color: $color-red;
    }
  }
}

.add-user-modal {
  .ui.form {
    .ui.multiple.selection.dropdown {
      min-height: 1.75rem !important;
      > .text {
        min-height: unset;
      }
    }
  }
}

.add-user-modal {
  .ui.form {
    .ui.multiple.selection.dropdown {
      min-height: 1.75rem !important;
      > .text {
        min-height: unset;
      }
    }
  }
}
