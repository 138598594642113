.rates-tables {
  min-height: 30rem;
  padding: 1rem;

  .ui.dropdown > .text {
    font-weight: bold !important;
  }

  .top {
    display: flex;
    flex-wrap: wrap;

    .top-container-1 {
      margin-right: 1rem;
      min-width: 51em;
    }
    .top-container-2 {
      min-width: 40em;
    }

    .header-dropdown {
      display: flex;
      align-items: center;
      margin: 1rem 1rem 1rem 0;

      label {
        margin-right: 1rem;
      }

      &.rate-table-type {
        .ui.dropdown .menu .item {
          padding: 0.2em 0.4em !important;
        }
      }
    }
  }

  .bottom {
    display: flex;
    flex-wrap: wrap;

    .bottom-container-1 {
      margin-right: 1rem;
      min-width: 51em;
    }
    .bottom-container-2 {
      min-width: 40em;
    }

    .header-dropdown {
      display: flex;
      align-items: center;
      margin: 1rem 1rem 1rem 0;

      label {
        margin-right: 1rem;
      }

      &.rate-table-type {
        .ui.dropdown .menu .item {
          padding: 0.2em 0.4em !important;
        }
      }
    }
  }
}

.tab-container {
  margin-left: 1em;
  display: inline-block;
  &.active {
    border-bottom-style: solid;
    border-bottom-color: $colour-standard-turquoise;
    border-bottom-width: 3px;
  }
}

.rrp-field-table {
  display: flex;
  justify-content: right;
  margin-top: 1em;
  align-items: center;

  .rrp-input {
    .field {
      display: flex;
      .read-only {
        width: 5em !important;
      }
      .ui.input {
        width: 5em !important;
      }
    }
  }
  .rrp-buttons {
    min-width: 12em;
  }
}
