.funder-details {
  max-width: 130em;
  margin: auto;

  .ui.menu {
    width: 29.75em;
    margin: 10px auto;

    .item {
      font-size: 12px;
    }

    .active.item {
      background-color: $color-lighter-yellow;
    }
  }

  .red-highlight {
    color: $color-red;

    &.right {
      text-align: right;
      padding: 1.5em;
      font-size: 1.2em;
      font-weight: bold;
    }
  }

  @media only screen and (min-width: 1300px) {
    .ui.menu {
      margin-top: -4em;
    }
  }

  .ui.form.funder-details {
    .field .ui.input input,
    .ui.form .field .ui.input,
    .ffDHgC .ui.input,
    .ui.disabled.dropdown,
    .ui.dropdown,
    .react-datepicker-wrapper {
      max-width: 100% !important;
      min-height: 1.75rem !important;
      text-align: center;
    }
    .ui.dropdown {
      max-width: 100% !important;
      min-height: 1.75rem !important;
    }
    .read-only {
      max-width: 100% !important;
      min-height: 1.75rem !important;
      text-align: center !important;
      width: 22.5em !important;
      display: block;
    }
    .disabled input {
      border: 0px !important;
    }

    .ui.grid > .row {
      padding: 0.2em;
    }

    .disabled.field,
    .ui.disabled.input,
    .ui.input:not(.disabled) input[disabled],
    .ui.form .disabled.fields .field,
    .ui.form .disabled.field,
    .ui.form .field :disabled,
    .ui.disabled.dropdown {
      opacity: 1 !important;
    }

    .ui.grid {
      background-color: white !important;
      overflow-y: visible !important;
      margin-bottom: 0.5rem;
    }

    .flex-items {
      display: flex;
      justify-content: space-between;
    }
  }

  .top-spacer {
    margin-top: 3rem;
  }
}

.margin {
  margin-bottom: 1.35em !important;
}

.flex-items {
  margin-bottom: 0.35em;

  > div.field {
    display: inline-block;
    vertical-align: top;
    width: 49%;
    &:first-child {
      margin-right: 9px !important;
    }
    label {
      font-size: revert;
      font-weight: 500;
    }
  }
}
