.system-page-content {
  background-color: $colour-standard-turquoise;
  height: 100vh;
  div.flex-spacer {
    flex-grow: 1;
  }
  & p {
    &.form-error {
      font-weight: 700;
    }
  }
  & .page-header {
    font-size: 20px;
    font-weight: normal;
  }
  .action-button {
    background-color: $colour-light-blue;
    font-size: 17px;
    color: $colour-white;
    border-radius: 97px;
    margin-top: 1em;
    &:hover {
      background-color: $colour-blue;
      color: $colour-white;
    }
  }
  div.error.message {
    margin: 1rem auto;
    > div.content {
      text-align: center;
    }
  }
  & .ui.grid {
    height: 100%;
    margin: 0;
    overflow-x: hidden;
    overflow-y: auto; //the shorthand `overflow` isn't supported by Edge or Safari
    background-color: $colour-white;
    div.content-background {
      background: $colour-white;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
    *.content-foreground {
      z-index: 10;
      > div.content {
        display: inline-flex;
        flex-direction: column;
        padding-top: 5vh;
        height: 95vh;
        width: 100%;
      }
      div.sys-actions {
        margin: 1em 0 0;
        width: 100%;
        > a {
          display: inline;
          color: initial;
          text-decoration: underline;
        }
        > span {
          padding: 0 0.3em;
        }
        ~ div.standoff {
          min-height: 1em;
        }
      }
      div.sys-form {
        margin-top: 5rem;
        > div.page-header {
          color: inherit;
        }
        .action-button {
          width: 100%;
          margin: 1em 0;
        }
        div.field {
          div.input {
            width: 100% !important;
          }
        }
      }
      div.login-form {
        margin-top: 6rem;
        h2 {
          margin-top: 0;
          font-size: 30px;
        }
        h3 {
          text-transform: uppercase;
          font-weight: 500;
          font-size: 16px;
        }
        div.forgot-password {
          text-align: start;
          margin-top: 2rem;
          > a {
            display: inline;
            font-weight: 700;
            margin-left: 0.5em;
            &:hover {
              color: $colour-white;
              background-color: $colour-blue;
            }
          }
        }
        .ui.error {
          display: inline-block;
        }
      }
    }
    & > .column:first-child {
      display: flex;
      flex-direction: column;
      align-items: center;
      & section {
        min-width: 30rem;
        div.content > a {
          display: contents;
          > img.logo {
            height: 12rem;
          }
        }
        & .ui.form {
          padding-top: 1em;
          text-align: end;
          & label {
            font-size: 16px;
            text-transform: none;
            text-align: start;
          }
          & input {
            background-color: $colour-white;
            border: 0.5px solid $colour-grey;
            border-radius: 4px;
          }
          & input[type="password"],
          input[type="text"] {
            padding-left: 15px;
          }
          & .ui.checkbox {
            margin: 0;
          }
          a {
            transition:
              color 0.1s ease,
              background-color 0.1s ease;
          }
          & .ui.button.primary {
            width: 100%;
            margin-top: 2rem;
          }
          .ui.message {
            max-width: 22.5em;
          }
        }
        @include customBreakpoint("min-width: 1200px") {
          @include customBreakpoint("max-width: 1420px") {
            min-width: 26rem;
          }
        }
        @include breakpoint-sm {
          min-width: 100%;
        }
      }
    }
  }
  & .ui.grid > .column {
    & :first-child > section {
      margin-top: 12%;
      & .image.logo {
        margin-bottom: 20%;
      }
    }
  }
  & .ui.error p {
    color: $text-color;
  }
  .forgotten-password-form-container,
  .user-setup-form-container,
  .password-reset {
    label,
    .title {
      color: $colour-white !important;
    }
    .title {
      font-size: 20px;
    }
    .ui.input {
      width: 100% !important;
    }
  }

  .email-verification-button-container {
    width: 20em;
    .ui.button {
      width: 100%;
      &.amethyst {
        background-color: $colour-purple;
      }
    }
  }
}
div.system-page-backdrop {
  z-index: 0;
  position: absolute;
  height: 100vh;
  width: 62.5vw;
  top: 0;
  right: 0;
  padding: 0;
  overflow: hidden;
  background-image: url("../../opal-cover.jpg");
  background-size: cover;
  @include customBreakpoint("max-width: 1199px") {
    width: 50vw;
    div.orb {
      display: none;
    }
  }
  img {
    position: absolute !important;
    min-height: 101vh; //deliberately overflow slightly
    width: 70vw;
    &:first-of-type {
      top: 70vh;
      transform: scale(1.5);
    }
    &:nth-of-type(2) {
      top: 33vh;
      transform: rotateY(180deg);
    }
    &:nth-of-type(3) {
      top: 40vh;
      transform: scale(1.05);
    }
    &:nth-of-type(4) {
      top: 26vh;
      transform: scale(1.7);
    }
  }
  div.orb {
    position: absolute;
    height: 23px;
    width: 23px;
    background-color: $colour-blue-grey;
    border: solid 2px $colour-blue-grey;
    border-radius: 50%;
    filter: blur(1px);
    &:first-of-type {
      top: 63vh;
      left: 11vw;
      @include breakpoint-not-desktop {
        top: 67vh;
      }
    }
    &:nth-of-type(2) {
      top: 86vh;
      left: 24vw;
    }
    &:nth-of-type(3) {
      top: 46vh;
      left: 54vw;
      @include breakpoint-not-desktop {
        top: 52vh;
      }
    }
    &:nth-of-type(4) {
      top: 69vh;
      left: 51vw;
      @include breakpoint-not-desktop {
        top: 73vh;
      }
    }
    &:nth-of-type(5) {
      top: 80vh;
      left: 41vw;
    }
    &:nth-of-type(6) {
      top: 93vh;
      left: 51vw;
      @include breakpoint-not-desktop {
        top: 97vh;
        left: 82vh;
      }
    }
  }
  @include customBreakpoint("max-width: 991px") {
    display: none;
  }
}
