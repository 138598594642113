.companies-container{
  padding: 0px 10px 10px 10px ;
  max-width: 130em;
  margin: auto;
  .ui.menu{
    width: 39.7em;
    margin: 10px auto ;
    .item{
      font-size: 12px;
    }
    .active.item{
      background-color: $colour-white-turquoise;
    }
  }

  .red-highlight{
    color: $color-red;
    &.right{
      text-align: right;
      padding: 1.5em;
      font-size: 1.2em;
      font-weight: bold;
    }
  }
 
  @media only screen and (min-width: 1300px) {
    .ui.menu{
    margin-top: -4em;
    }
  }


  .company-schedules{
    .contract-ref, .contract-master-date{
      font-weight: bold;
    }
    .attachment-link a, i.icon{
      text-decoration: none;
    }
    button.ui.primary.button{
      padding: 10px 30px;
    }
  }

    .attachments-list{
      .cin.action-bar {
        margin-bottom: 0em;
      }
      .ui.input.search-input.highlighted-input{
        margin-top: 3px;
      }
    }

    .signatories-container{
      .historic-btn-container{
        padding: 10px 0px;
        text-align: right;
        button.ui.primary.button{
          padding: 10px 30px;
        }
      }
    }

  }


