.schedule-wizard-navigation {
  display: flex;
  padding: 10px;
  overflow-x: auto;
  div {
    cursor: pointer;
    p {
      margin-right: 1.5em;
      font-size: 13px;
      white-space: nowrap;
    }
  }

  .active {
    font-weight: bold;
    text-decoration: underline;
  }
}
