.content-panel.yield-tables {
  padding: 1rem;

  .yields-container {
    display: flex;
    min-height: 30rem;
    overflow-x: auto;
    margin-top: 2em;
    .main-section,
    .comparison-section {
      min-width: 51rem;
      max-width: 46rem;
      margin-right: 5rem;
    }
  }

  .rate-table-group {
    display: flex;    
    flex-wrap: wrap;    
  }

  .header-dropdown {
    display: flex;
    align-items: center;
    @include customBreakpoint("max-width: 620px") {
      flex-direction: column;
      align-items: flex-start;
    }
    label {
      margin-right: 1rem;
    }
    margin: 0.25rem 2rem 0.25rem 0;
    &.rate-group {
      margin-top: 0;
      max-width: 60rem !important;
    }
    
    &.rate-table-type {
      .ui.dropdown .menu .item {
        padding: 0.2em 0.4em !important;
      }
    }
    .ui.dropdown > .text {
      min-width: 20em;
      font-weight: bold;
    }
    > .ui.visible.upward.dropdown {
      position: absolute;
    }
  }
}
.dropdown-label {
  width: 7em
}
.rate-group-label {
  margin-right: 1rem;
}
