.download-template-modal{
    .ui.form{
        max-width: 22.5em !important;
    }
    label{
        font-weight: bold;
    }
}

.data-uploads-page {
    .ui.menu{
        width: 23.3em;
        margin: 1em auto ;
        .item{
            font-size: 0.9em;
        }
        .active.item{
            background-color: $colour-white-turquoise;
        }
    }

    .cin.action-bar .action-bar-item {
        .ui.button {
            white-space: nowrap;
        }
    }

    @include customBreakpoint("min-width: 767px") {
        .uploads-panel-container,
        .templates-panel-container {
            overflow: auto;
        }

        .content-panel.data-uploads-list {         
            min-width: 85em;        
        }
        .content-panel.templates-list {         
            min-width: 75em;        
        }
    }

    @include customBreakpoint("max-width: 766px") {
        .defaults-page-title {
            flex-direction: column;
            
            .fit-loader {
                overflow: auto;
                .cin.action-bar.filter-display {
                    flex-direction: row;
                    min-width: 50em;
                    .cin.action-bar-space { 
                        height:2px;
                    }
                }
            }
        }
    }
}