.meter-reading-form, .reject-meter-reading{
    .field{
        display:flex;
        justify-content: flex-start ;
    }
    .ui.form.ui.form label{
        padding: 0em 1em 1em 0em;
    }
    .ui.form .field textarea{
        width: 100% !important;
    }
    .ui.table {
        border-left: none;
        border-bottom: none;
        td{
            padding:0.2em 0.1em;
        }
        tr td:first-child, tr th:first-child {
            border-left: 1px solid $color-grey-1;
        }
        tr:last-child td {
            font-weight: bold;
            border-bottom: 1px solid $color-grey-1;
            &:first-child{
                background-color: $colour-white;
                border-bottom: none;
                border-left: none;
            }
        }
       
    }
    .ffDHgC .ui.input{ 
        max-width: 13em;
        margin: auto;
    }
     .ui.form .field .ui.input > input{
            text-align: center !important;
           
        }
}