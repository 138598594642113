.funder-container{
    padding: 0px 10px 10px 10px ;
    max-width: 130em;
    margin: auto;

 

  }

.funder-details{
  .ui.form .field{
    opacity: 1!important;    
    margin-bottom: 0.5em;
 
  }
  .custom-sublabel .field{
    margin-bottom: 0em !important;
  }
}

.funder-create-modal{
  &.yellow{
    .header{
      background-color: $color-lighter-yellow;
    }   
  }
}

.funders-settings{
  .ui.table td{
    padding:  0.3em 1em !important;
  }

  .ui.dropdown {
    margin-top: .35em !important;  
    > .text {
    min-width: 20em;
    font-weight: bold;
    }
  }
  
  .due-date-container {
    width:3em;
    margin-top: -0.3em !important;
    .ffDHgC .ui.input{
      width: 3em !important;
    }
  }
  div.content-panel .spaced .custom-sublabel{
    margin-bottom: 0.5em;
  }

  .ui.input input{
    text-align: left!important;
  }
 
  .ui.selection.dropdown{
    margin-bottom: 0em;
  }
  .supplier-form{
      max-width: 65em;
    .ui.label:not(.currency-label) {
      width: 35%!important;
    }
    
   .custom-sublabel .read-only{
      padding: 0.25em 0.75em !important;
    }
  }
  
  .ui.form .disabled.field{
    opacity: 1!important;    
    margin-bottom: 0.5em;
 
  }

  .ui.labeled.input.custom-sublabel{
    margin-bottom: 0.5em;
    .disabled.field{
      margin-bottom: 0em;
    }
  }

  .ui.disabled.input{
    opacity: 1!important;
    margin-bottom: 0em;
  }

  .ui.table.account-information, .self-bill-invoicing{
    border-top: 1px solid $colour-grey !important;
  }

  .self-bill-invoicing{
    max-width: 30em;
  }

  }
  