.dealers-container {
  padding: 0px 10px 10px 10px;
  max-width: 130em;
  margin: auto;
}

div.dealer-list {
  h3 {
    margin-bottom: 0.5rem;
    font-size: 1.2em;
    text-align: left;
  }
  img {
    image-orientation: from-image;
  }
  div.dealer-filters {
    min-height: 5rem;
    padding: 0.75em;
    button {
      margin: 0;
    }
  }
  div.tile-section {
    margin-top: 2em;
    div.accordion {
      div.title {
        &:not(.active) {
          border-bottom: 1px solid $colour-grey;
        }
      }
    }
  }
  div.tile-group {
    div.tile-grid {
      > div.cell {
        margin: 0.5em 0.5em 0.5em 0;
        border-radius: 5px;
        border-left: 5px solid $colour-standard-turquoise;
        box-shadow: 2px 2px 10px $colour-grey;
        transition:
          box-shadow 0.2s ease,
          filter 0.2s ease;
        &:hover {
          box-shadow: 2px 2px 10px $colour-dark-grey;
          filter: brightness(0.95);
          cursor: pointer;
        }
        div.dealer-tile {
          border: none;
          > *:not(img.logo) {
            display: inline-block;
          }
          h4 {
            font-size: 16px;
            width: 50%;
          }
          img.logo {
            max-width: 50%;
            height: 2.5em;
            float: right;
          }
          > div {
            margin-top: 0.5em;
          }
          div.contact-point {
            display: flex;
          }
          div.sites-info {
            span.number-formatter {
              font-weight: 700;
              margin-right: 0.25em;
            }
          }
        }

        a.dealer-tile {
          border: none;
          > *:not(img.logo) {
            display: inline-block;
          }
          h4 {
            font-size: 16px;
            width: 50%;
          }
          img.logo {
            max-width: 50%;
            height: 2.5em;
            float: right;
          }
          > div {
            margin-top: 0.5em;
          }
          div.contact-point {
            display: flex;
          }
          div.sites-info {
            span.number-formatter {
              font-weight: 700;
              margin-right: 0.25em;
            }
          }
        }
      }
    }
  }
}
div.modal.create-dealer,
div.modal.edit-dealer {
  max-height: 90vh; //the ArrayAdder means it keeps growing
  overflow-y: auto;
  div.custom-action-content {
    overflow-x: auto;
    min-width: 55rem; //the RolePicker needs the width really
  }
  div.input {
    width: 100% !important;
    min-width: 15em;
  }
  div.header {
    font-size: 1.4em !important;
  }
  div.step {
    &.details {
      > div.field {
        display: inline-block;
        vertical-align: top;
        width: 50%;
        padding-right: 2em;
        label {
          font-size: revert;
          font-weight: 500;
        }
      }
    }
    &.structure {
      div.field {
        margin: 0;
        padding: 0;
      }
      div.structure-header,
      div.structure-row {
        > * {
          display: inline-block;
          width: 12.5%;
          &:first-child {
            width: 50%;
          }
          @include breakpoint-sm {
            &:first-child {
              width: 45%;
              > button.inline {
                width: 15%;
              }
            }
          }
        }
      }
      div.structure-header {
        label {
          font-size: 1.1em;
          &:first-of-type {
            font-weight: 500;
          }
          &:not(:first-of-type) {
            font-weight: 700;
            text-align: center;
          }
        }
      }
      div.structure-row {
        display: flex;
        flex-direction: row;
        padding: 0;
        border-top: 1px solid $colour-translucent-offblack;
        transition: height 0.4s ease;
        &:nth-of-type(2n + 1) {
          background-color: $table-alternate-row-grey;
        }
        div.structure-name {
          padding: 0;
          overflow-x: auto;
          > * {
            display: inline-block;
          }
          i.icon.dropdown {
            width: 2%;
            margin: 0;
            transform: rotate(270deg);
            vertical-align: middle;
          }
          div.field {
            width: 98%;
            margin: 0;
            &:not(.error) {
              > :not(div.ui.input):not(div.read-only) {
                //prevent transparent validator occupying whitespace
                display: none;
              }
            }
            input[type="text"] {
              font-weight: 700;
              background-color: transparent;
            }
            div.read-only {
              padding: 0.75em 1em;
              > p {
                font-weight: 700;
                background-color: transparent;
              }
            }
          }
          div.error.field {
            p.form-error {
              font-weight: 700;
              margin-left: 1em;
            }
            input[type="text"] {
              border-color: $negative-color;
            }
          }
        }
        div.role-option {
          padding-top: 1%;
          border-radius: 4px;
          > .role-checkbox {
            color: $colour-white-green;
            background-color: transparent;
            border: solid 2px $colour-grey;
            width: 1.2em;
            height: 1.2em;
            margin: 0 auto;
            border-radius: 50%;
            &.checked {
              background-color: $positive-color;
            }
            &.disabled,
            &.read-only,
            &.checked {
              border-color: transparent;
            }
            i.icon {
              font-size: 0.75em;
              margin: 0 0 1em 0;
              width: 100%;
              height: 100%;
              position: relative;
              vertical-align: middle;
            }
          }
        }
        button {
          margin: 0 0 0 2.5%;
          width: 10%;
          text-align: center;
        }
      }
      button.inline {
        vertical-align: middle;
        color: $colour-standard-turquoise;
        &.adder {
          &::before {
            content: "+";
            margin-right: 0.5em;
            font-size: 1.75em;
            vertical-align: top;
          }
        }
      }
      div.structure-name div.field input[type="text"],
      div.role-option,
      button.inline {
        &:not(.disabled):not(.read-only) {
          border: solid 1px transparent;
          background-color: transparent;
          transition:
            border-color 0.1s ease,
            background-color 0.1s ease,
            box-shadow 0.1s ease;
          &:hover,
          &:focus:not(button),
          &:focus-within:not(button) {
            border-color: $colour-dark-grey;
            box-shadow: 2px 2px 10px $colour-dark-grey;
            background-color: $colour-light-grey;
            &:not(input) {
              cursor: pointer;
            }
          }
        }
      }
    }
    &.confirm {
      div.column.grid {
        width: 25em;
        margin-bottom: 0;
        margin-top: 0;
        > div.row {
          padding: 0;
          > div.column:nth-child(2) {
            font-weight: 700;
          }
        }
      }
    }
  }
  div.custom-action-footer {
    div.action-bar {
      margin-bottom: 0;
      div.action-bar-item {
        padding-bottom: 0;
        button {
          transition:
            background-color 0.2s ease,
            color 0.2s ease;
          &.loading {
            &::before {
              border-color: $colour-black;
            }
          }
          &.cancel {
            background-color: transparent;
          }
          &.previous {
            background-color: $colour-translucent-offblack;
            color: $colour-offwhite;
          }
          &:hover:not(.primary) {
            background-color: $colour-translucent-dark-grey;
            color: $colour-dark-grey;
          }
        }
      }
    }
  }
}
.details-header {
  .details-header-row {
    display: flex;
    justify-content: space-between;
    padding: 1em;

    .data-field {
      display: flex;
      .field {
        width: 7em;
        white-space: nowrap;
      }
      .value {
        font-weight: bold;
        margin-left: 1em;
      }
    }
    @include customBreakpoint("max-width: 1100px") {
      flex-direction: column;
      justify-content: flex-start;
    }
  }
  .bold {
    font-weight: bold;
  }
}
.request-emails-header {
  .request-emails-header-row {
    display: flex;
    justify-content: space-between;
    padding: 1em;

    .data-field {
      display: flex;
      .field {
        width: 7em;
        white-space: nowrap;
      }
      .value {
        font-weight: bold;
        margin-left: 5em;
      }
    }
    @include customBreakpoint("max-width: 1100px") {
      flex-direction: column;
      justify-content: flex-start;
    }
  }
  .bold {
    font-weight: bold;
  }
}
.dealer-summary-content {
  margin: 5px 0 0 10px !important;
}
#tabs > .ui.menu .active.item {
  text-decoration: underline !important;
  font-weight: bold !important;
}
.office-logo {
  float: right;
  height: 3em !important;
}
.office-header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 2em;
  .office-header-group {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    .data-field {
      display: flex;
      justify-content: flex-start;
      .value {
        font-weight: bold;
        margin-left: 1rem;
      }
    }
    &.group1 {
      .data-field {
        .field {
          width: 6rem;
        }
        &:first-child {
          margin-bottom: 0.75rem;
        }
      }
    }
    &.group2 {
      .data-field {
        &:first-child {
          margin-bottom: 0.75rem;
        }
      }
    }
    &.group3 {
      .data-field {
        justify-content: space-between;
      }
    }
  }
}
div.action-bar {
  &.auth-signatories {
    div.action-bar-item {
      &:first-child {
        margin-left: 2em;
      }
    }
  }
}

.delete-supplier {
  display: flex;
  justify-content: space-between; // horizontally align to the right
}

.delete-supplier {
  margin-bottom: 0.75rem;
}

h2 {
  margin-bottom: 0rem;
}

h4.supplier-title {
  padding: 0.5em 0em 0em 0em;
  margin: 0em;
  font-size: 1.4em;
}

.request-email-content {
  margin-top: 2em;
  div.read-only {
    > p {
      font-weight: bold;
    }
  }
}

.dealer-header {
  margin: auto;
  margin: 10px 0 0 0;
  > div:first-child {
    margin-bottom: 2em;
  }
  .ui.form.dealer-header {
    .field .ui.input input,
    .ui.form .field .ui.input,
    .ffDHgC .ui.input,
    .ui.disabled.dropdown,
    .ui.dropdown,
    .react-datepicker-wrapper {
      max-width: 100% !important;
      min-height: 1.75rem !important;
      text-align: center;
    }
    .ui.dropdown {
      max-width: 100% !important;
      min-height: 1.75rem !important;
    }

    .ui.grid > .row {
      padding: 0.2em;
    }

    .disabled.field,
    .ui.disabled.input,
    .ui.input:not(.disabled) input[disabled],
    .ui.form .disabled.fields .field,
    .ui.form .disabled.field,
    .ui.form .field :disabled,
    .ui.disabled.dropdown {
      opacity: 1 !important;
    }

    .ui.grid {
      background-color: white !important;
      overflow-y: visible !important;
      margin-bottom: 0.5rem;
    }
  }
}

.dealer-form-header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 0.25em;

  :first-child {
    label {
      width: 9em !important;
    }
  }
  label {
    width: 5.5em !important;
  }

  label {
    width: 9em !important;
    margin-bottom: 1em !important;
  }
}
.hq-flex {
  display: flex;
  width: 100% !important;
}

.hq {
  width: calc(100% - 10em) !important;
  .ui.disabled.input {
    width: 100% !important;
  }
}

.document-numbering-content {
  display: flex;
  font-size: 14px;
  .character-range-content {
    div .ui.input {
      width: 3em !important;
    }
    margin: 1em;
    .character-range {
      display: flex;
      justify-content: center;
      justify-content: space-evenly;
    }
  }
  .starting-number-content {
    div .ui.input {
      width: 6em !important;
    }
    margin: 1em;
  }
}
