.cin.page-content.manage-schedule {
  max-width: 1600px !important;
}

.lfpConfigTable {
  button.ui.primary.button {
    color: black;
    background-color: transparent;
    font-size: 1em;
    padding: 0.5em;
    font-weight: bold;
    text-decoration: underline;
    &:hover {
      color: $color-blue-alt;
      background-color: transparent !important;
    }
  }

  tbody tr td:last-child {
    button.ui.primary.button {
      color: red;
      float: none;
    }
  }
}

.lf-paper-sku-modal {

  width: calc(100% - 2rem) !important;
  max-width: 90rem;
  font-size: 12px !important;

  .ui.table {
    text-align: center;
    min-width: 100em;
  }

  .ui.table thead th,
  .ui.table thead td
  {
    padding: 0.1em 0.25em;    
  }

  .ui.positive.button {
    background-color: $colour-standard-turquoise !important;
    i {
      display: none;
    }
  }

  .ui.table thead tr th {
    background-color: $colour-white-turquoise;
    border-left: 1px solid $color-grey-1;    
  }

  .ui.table tbody tr:nth-child(even) {
    background: $table-alternate-row-grey;
  }

  .ui.table tbody tr td {
    border-left: 1px solid $color-grey-1;
  }

  .ui.table:not(.unstackable) {
    @media only screen and (max-width: 767px) {
      thead tr {
        padding-top: 0px;
        padding-bottom: 0px;
      }
    }
  }
}

.lfui-modal {
  .ui.positive.button {
    background-color: $colour-standard-turquoise !important;
    i {
      display: none;
    }
  }
}

.largeFormatUnitInfo {
  .ui.table thead tr th {
    background-color: $colour-white-turquoise;
    border-left: 1px solid $color-grey-1;
  }
  .ui.table tbody tr:nth-child(even) {
    background: $table-alternate-row-grey;
  }

  .ui.table tbody tr td {
    border-left: 1px solid $color-grey-1;
  }

  .unitInfo {
    margin-bottom: 25px;
  }

  .infoRow {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 16px;
    margin-bottom: 10px;

    .infoHeader {
      font-weight: bold;
      min-width: 100px;
    }
    .infoValue {
      margin-left: 5px;
    }
  }
}

.schedule-price-increase {
  .price-increase-buttons {
    display: flex;
    justify-content: right;
    padding: 1em 0em 0em 0em;
  }

}

