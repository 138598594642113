.defaults-grid.payment-method-restrictions {
  .inline.field label,
  .data-field .field {
    width: 17rem;
    margin-right: 0.5rem !important;
  }

  .inline.field label {
    white-space: nowrap;
    overflow: visible;
  }

  .data-field {
    display: flex;
    margin-top: 0.5em;
    font-size: 1em;
    .field {
      font-weight: bold;
    }
  }

  .default-available-wrapper {
    display: flex;
    flex-wrap: nowrap;
    .field:first-child {
      margin-right: 1rem;
    }
    .ui.radio.checkbox {
      display: flex;
      justify-content: center;
    }    
  }

  .ui.checkbox.read-only > label {
    cursor: not-allowed;
  }
}
