.product-meter-reading {
    .ui.menu {
    width: 258px;
    margin: 10px auto;
      .item {
        font-size: 12px;
      }
      .active.item {
        background-color: $colour-white-turquoise;
      }
  }

 .ui.table td {
      padding: 0.1em;
  }

  .extension-icon {
    float: left;
  }

  .buttons {
    float: right;
  }

  .ui.primary.right.floated.button {
    margin-right: 10px;
    margin-bottom: 5px;
  }

  .tbody .tr .blue {
    color: $color-blue-alt;
  }

  .tbody .td .blue {
    color: $color-blue-alt;
  }

  .tbody .td &hyperlink {
    text-decoration-line: underline;
  }

  .left.menu.transition {
    .item {
      text-decoration-line: none;
    }
  }

  .key {
    margin-top: 10px;
    text-align: center;
  }

  .flex{
    .align-right{
      margin-left: auto;
      padding-bottom: 0.6em;
    }
    .title{
      padding-top: 0.6em;
    }
  }
}

