// place any app-specific variables here or override Cinnamon/Semantic defaults

//breakpoints - be careful these affect the whole app
$sm-tablet-min-width: 501px; // sm tablet - 501px - 799px. Purposefully 501px so it can be tested on Desktop Chrome
$tablet-min-width: 800px; // tablet 800px - 1064px
$laptop-min-width: 1065px; // laptop 1065px - 1399px
$desktop-min-width: 1400px; // desktop 1400px - inf

//whites
$colour-white: #ffffff;
$color-white: #ffffff;
$colour-offwhite: #faf9fc;
$colour-white-green: #eef8ef;

//greys
$colour-grey: #979797;
$colour-light-grey: #f1f2f3;
$colour-light-grey-accent: #eae9ec;
$colour-dark-grey: #3f3f3f;
$colour-translucent-dark-grey: #22242626;

$color-grey-1: #cccccc;
$color-grey-2: #979797;
$color-grey-3: #707070;
$color-grey-4: #4e4e4e;
$color-grey-5: #404040;
$color-grey-6: #393939;
$color-grey-7: #d6d6d6;
$color-grey-8: #dddddd;
$light-grey: #f1f2f3;
$pale-grey: #ccc;
$light-grey-1: #d6d6d680;

$text-color: $color-grey-5;
$table-alternate-row-grey: #f1f2f3;
$table-footer-grey: #dbdbdb;

//blacks
$colour-black: #000;
$colour-translucent-offblack: #08080875;

//purples
$colour-purple: #6b036b;
$colour-light-purple: #5b2779;
$colour-light-purple-hover: #d6b7e8;
$color-lighter-purple: rgb(180, 167, 214, 0.5);

//violet
$colour-violet: #876bcc;
$colour-light-violet:#b5a8d3;

//blues
$colour-blue: #004a96;
$colour-dull-blue: #5d79a0;
$color-standard-blue: #0050a0;
$color-standard-light-blue: #e3f1ff;
$color-lighter-grey-blue: #f0f8ff;
$colour-blue-grey: #8190a4;
$colour-light-blue: #3caae6;
$colour-light-blue-hover: #8fcef2;
$colour-lighter-blue: #ecf7fd;
$colour-hyperlink-blue: blue;
$color-blue-alt: #4183c4;


//turqoises
$colour-standard-turquoise: #00a0a0;
$colour-standard-turquoise-light: #c3fafa;
$colour-standard-turquoise-dark: #30618c;
$colour-translucent-standard-turquoise: #40e0d021;
$colour-white-turquoise: #d9f1f1;
$colour-dark-turquoise: #134f5c;
$color-turquoise-highlight: #ecf8f8;

//use-case specific
$background-color: $colour-offwhite;
$nav-border-colour: $colour-white-turquoise;
$widget-background-colour: $colour-white;
$widget-border-colour: $colour-light-grey-accent;
$nav-active-colour: $colour-standard-turquoise;
$nav-foreground-colour: $colour-standard-turquoise;
$nav-hover-colour: $colour-standard-turquoise-light;
$background-colour-funder: #B5D1F5;

//greens
$color-light-green: #bee9be;

$color-orange: #ff7700;
$color-green: #3eab42;

//reds
$color-red: #ff0000;
$color-red-light: #fd8f8f;
$color-red-lighter: #fbc0c0;

//yellows
$color-yellow: #ffbb00;
$color-light-yellow:#fff2cc;
$color-dark-yellow: rgb(191,144,0);
$color-lighter-yellow: rgb(251,232,175);

//opal schedule colours
$colour-header-turquoise: #a2c4c9;

