.ui.form.customer-details,
.ui.form.banking-form {
  .field .ui.input input,
  .ui.form .field .ui.input,
  .ffDHgC .ui.input,
  .ui.disabled.dropdown {
    max-width: 100% !important;
  }
  .ui.grid > .row {
    padding: 0.2em;
  }
  .disabled.field,
  .ui.disabled.input,
  .ui.input:not(.disabled) input[disabled],
  .ui.form .disabled.fields .field,
  .ui.form .disabled.field,
  .ui.form .field :disabled,
  .ui.disabled.dropdown {
    opacity: 1 !important;
  }
  .regNo {
    max-width: 200px;
  }
}

.company-summary {
  .invoice-table.ui.table {
    border-left: none !important;
    border-bottom: none !important;
    th:nth-child(1),
    td:nth-child(1) {
      border-left: 1px solid $color-grey-1;
    }
    tbody tr:last-child {
      td {
        background-color: $colour-white;
        border: none;
      }
      td.has-total-row {
        background-color: $colour-standard-turquoise;
        color: #fff;
        font-weight: bold;
      }
    }
  }
}

.products-list {
  .companies-products {
    margin: 1em !important;
    margin-top: 0.5em !important;
    .vertical-scrollbar {
      height: 440px;
    }
  }
  .companies-products-status {
    text-align: center;
    margin-bottom: 0.5em;
  }
  .search-filter-display {
    margin-bottom: 0 !important;
  }
}

.banking-list {
  table.banking-table {
    table-layout: fixed;
    border-left: transparent;
    .sticky-col {
      position: sticky !important;
      left: 0px;
      background-color: $colour-white-turquoise;
      padding: 0.78571429em 0.78571429em;
      text-align: left !important;
      border-bottom: 1px solid $color-grey-1;
      border-right: 1px solid $color-grey-1;
      border-left: 1px solid $color-grey-1 !important;

      white-space: nowrap;
      min-width: 200px;
      width: 200px;
    }
    .fixed-col {
      min-width: 200px;
      width: 200px;
    }
  }

  table.sorting-banking-table tbody {
    display: inline-block;
  }
}

.invoices-list {
  .invoice-details-table.ui.table {
    border-left: none !important;
    border-bottom: none !important;
    th:nth-child(1),
    td:nth-child(1) {
      border-left: 1px solid $color-grey-1;
    }
    tbody tr:last-child {
      td {
        background-color: $colour-white;
        border: none;
      }
      td.has-total-row {
        background-color: $colour-standard-turquoise;
        color: #fff;
        font-weight: bold;
      }
    }
  }
}

.checkbox-icon-container {
  display: flex;
  align-items: center;

  .ui.checkbox {
    margin-right: 1em;
    padding-top: 1em;
  }

  .disable-icon {
    cursor: pointer;
  }
}
