$colour-border-dark: $color-grey-4;

.cfa-summary {

    table.ui.table {
        margin: 0 !important;
        border: none !important;

        tr td:first-child {
            text-align: left !important;
        }

        tr:nth-child(even) {
            background-color: white !important;
        }

        tr.highlighted td {
            background-color: $colour-white-turquoise;
        }

        tr.grey td {
            background-color: $color-grey-7 !important;
        }

        td.grey {
            background-color: $color-grey-1 !important;
        }

        td.italics {
            font-style: italic;
        }

        tr.bold td {
            font-weight: 700;
        }

        td.bold {
            font-weight: 700;
        }

        tbody tr td {
            border-left: none!important;
         }
         tbody tr td:first-child {
            border-left: 1px solid $color-grey-1!important;
         }

         tbody tr:first-child td:first-child {
            border-top: 1px solid $color-grey-1;
            border-left: 1px solid $color-grey-1;
            border-top-left-radius: 0.28571429rem;       
            background-color: $color-grey-7;
         }
         tbody tr:first-child td:last-child {
            border-top: 1px solid $color-grey-1;
            border-right: 1px solid $color-grey-1;
            border-top-right-radius: 0.28571429rem;          
            background-color: $color-grey-7;
            width: 35%;
         }

        tr td {
            padding: 0px;
        }
        tbody tr.table-divider td {
            background-color: $colour-dark-turquoise;
            border-left: solid 1px $colour-dark-turquoise !important;
            border-right: solid 1px $colour-dark-turquoise !important;
            padding: 3px;
        }
    }

    .row {
        padding: 0px !important;
    }

    .term p {
        margin-top: 5px;
        font-size: 10px;
    }
    .term-table {
        tbody tr:first-child td:first-child {
            border-top: 1px solid $colour-dark-turquoise!important;
            border-left: 1px solid $colour-dark-turquoise!important;   
            background-color: $colour-dark-turquoise!important;
        }
        tbody tr:first-child td:last-child {
            border-top: 1px solid $colour-dark-turquoise!important;
            border-right: 1px solid $colour-dark-turquoise!important;  
            background-color: $colour-dark-turquoise!important;
        }
    }
    .content-panel{
        margin-bottom: 2em;;
    }


}

.cfa-charge{
    &.ui.form .field{
        opacity: 1!important;    
        margin-bottom: 0.5em!important;
    }
    form{
        max-width: 65em;
        .ui.label:not(.currency-label) {
        width: 35%!important;
        }
    }
    .ui.form .disabled.field{
        opacity: 1!important;    
        width: 100%;
        margin-bottom: 0.5em!important;
    }
    
    .ui.input,.ffDHgC > textarea{
        width: 100% !important;
    }
    
    .ui.disabled.input{
        opacity: 1!important;
    }
    .ui.labeled.input.custom-sublabel{
        height: 18.7em !important;
        .ui.label:not(.currency-label){
            width: 15% !important;
        }
        .disabled.field{
            margin-bottom: 0em !important;
        }
    }
    .padded, textarea{
        height: 100%;
    }
}