 .schedule-charges {
 .ui.button.po-lock-btn{
    margin-left: 120px;
    background: transparent !important{}
    &.icon, &.icon:hover {
      color: $colour-black;
      
    }
  }
  .ui.table{
    border-bottom: none !important;
    border-left: none !important;
           
    td.border-left, td:first-child{
      border-left: 1px solid $color-grey-1 !important;
    }
    
    td.empty
    {
      border-right: none !important;
      border-left: none !important;
    }
    
    td.highlight{
      padding: 5px !important;
      border-left: 1px solid $color-grey-1 !important;
      border-right: 1px solid $color-grey-1 !important;
      border-bottom: 1px solid $color-grey-1 !important;
      font-weight: strong;
    }       
  }
  .poRef{
    max-width: 150px;
    width: 150px;
  }
}
 