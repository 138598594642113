div.dashboard {
  min-width: 100%;
  h2 {
    font-weight: 500;
    font-size: 19px;
  }
  div.widget-row {
    display: flex;
    min-height: 35vh;
  }
  div.widget,
  div.widget-column {
    display: inline-block;
    margin: 0.25%;
    &.w-25 {
      width: 25%;
      @include breakpoint-md {
        width: 50%;
      }
    }
    &.w-50 {
      width: 50%;
      @include breakpoint-md {
        width: 75%;
      }
    }
    &.w-75 {
      width: 75%;
      @include breakpoint-md {
        width: 100%;
      }
    }
  }
  div.widget {
    padding: 1em;
    background-color: $widget-background-colour;
    border: solid 2px $widget-border-colour;
    border-radius: 4px;
    box-shadow: 4px 3px 3px $widget-border-colour;
  }
  div.widget-column {
    div.widget {
      display: block;
      margin: 0.5% auto;
      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  ul.horizontal > li {
    display: inline-block;
    &::before {
      content: "\00a0\2022\00a0\00a0";
    }
    &:not(:first-of-type) {
      margin-left: 2em;
    }
  }

  /*
    *   styles for placeholders
    *   these can go once placeholders replaced with actual widget data
    */
  div.widget.barChart-placeholder {
    div.barChart-average {
      display: inline-block;
      font-weight: 700;
      font-size: 15px;
      width: 100%;
      margin-bottom: 1em;
      color: $colour-standard-turquoise;
    }
    h2 {
      display: inline-block;
    }
    div.barChart-filters {
      float: right;
      div.field {
        display: inline-block;
        div.ui.dropdown {
          width: 11em !important;
        }
      }
      ~ img {
        width: 90%;
        margin: 0 auto;
      }
    }
  }
  div.widget.new-agreements-placeholder,
  div.widget.customer-invoice-placeholder {
    div.split {
      display: inline-block;
      &:first-of-type {
        width: 70%;
      }
      &:last-of-type {
        width: 30%;
      }
      > img {
        float: right;
      }
      div:first-child {
        font-weight: 700;
        font-size: 16px;
      }
    }
  }
  div.widget.new-agreements-placeholder {
    div.split {
      div:first-child {
        color: $colour-standard-turquoise;
      }
    }
  }
  div.widget.customer-invoice-placeholder {
    div.split {
      div:first-child {
        color: $colour-light-purple;
      }
      i {
        margin-left: 1em;
        font-size: 0.8em;
      }
    }
  }
  div.widget.mini-doughnut-placeholder {
    div.row {
      border-bottom: solid 1px $colour-light-grey;
      padding: 0.5em 0;
      &:first-child {
        padding-top: 0;
      }
      &:last-child {
        padding-bottom: 0;
        border-bottom: none;
      }
      div.split {
        display: inline-block;
        &:first-child {
          width: 20%;
        }
        &:last-child {
          width: 70%;
          vertical-align: top;
          padding-top: 1em;
        }
      }
    }
  }
  div.widget.big-doughnut-placeholder {
    text-align: center; //to stop the <li>s stretching out randomly
    h2 {
      text-align: initial;
    }
    img {
      margin: 2.5em auto;
    }
    ul {
      //*** - top stop the <li>s stretching out randomly *****
      display: inline-block;
      text-align: left;
      //*****************************
      padding: 0;
      font-size: 15px;
      > li:first-of-type {
        color: $colour-light-purple;
      }
      > li:nth-of-type(2) {
        color: $colour-light-blue;
      }
      > li:nth-of-type(3) {
        color: $colour-standard-turquoise;
      }
      span {
        color: initial;
      }
    }
  }
  div.widget.skinny-barChart-placeholder {
    img {
      width: 90%;
      margin: 2em auto;
    }
    ul {
      text-align: center;
      padding: 0;
    }
  }
  div.widget.line-graph-placeholder {
    img {
      width: 100%;
      max-height: 75%;
    }
  }
  /*
        *   end placeholder styles
        */
}
