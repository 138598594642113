div.content-panel {
  .funder-type-settings {
    text-align: left;
    .ui.table {
      border-top: 1px solid $color-grey-1;
      .ui.checkbox {
        margin-bottom: 0em !important;
      }
    }

    .funder {
      .ui.table tbody tr:nth-child(even) {
        background-color: $color-light-yellow !important;
      }
    }

    .dealer {
      .ui.table tbody tr:nth-child(even) {
        background-color: $color-light-green !important;
      }
    }

    .co-funded {
      .ui.table tbody tr:nth-child(even) {
        background-color: $color-lighter-purple !important;
      }
    }

    .ui.grid > .row {
      padding: 0px;
    }

    .ui.table tbody tr td {
      max-width: 60em;
      font-size: 1.1em;
      span {
        color: $colour-purple;
      }
    }
    .ffDHgC .ui.input {
      max-width: 9em !important;
      float: left;
      font-size: 1.1em !important;
    }
    .has-label .ffDHgC .ui.input {
      max-width: 7.9em !important;
    }

    .no-left-padding {
      margin-left: 0px !important;
      padding-left: 0px !important;
    }

    .display-default {
      .ui.checkbox {
        padding-left: 9em !important;
        margin-top: 0.75em !important;
      }
    }
  }
}
