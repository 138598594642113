.products{
    .header{
        .ui.form{
            @media only screen and (min-width: 1000px) {
                display: flex;
                justify-content: space-around;
            }
            width: 100%;
            .ui.disabled.input , .field.disabled  {
                margin-bottom: 0px;
                width: 100% !important;
                opacity: 1 !important;
                padding: 0.2em;
                padding-right: 1em;
            }
        }
        h2{
            margin-top: 0px;
            @media only screen and (min-width: 1100px) {
            padding-right: 2em;      
            width: 35em;          
                &::after {
                    content: ":";
                }
                &:nth-of-type(2) {
                    padding-left: 1em;
                    width: 25em;
                }
            }
        }
    }

    .ui.menu{
        width: 26.2em;
        margin: 10px auto ;
        .item{
            font-size: 0.9em;
        }
        .active.item{
            background-color: $colour-white-turquoise;
        }
    }

    
    .product-summary-container {
        .field{
            margin-bottom: 0em !important;
        }
        .ui.disabled.input , .field.disabled  {
            opacity: 1 !important;
            padding: 0.2em;
            @media only screen and (min-width: 1100px) {
                padding-right: 1em;
            }
            width: 100% !important;
        }
        h3{
            margin-top: 0.5em;
            min-width: 8em;
        }
        h4{
            margin-top: 0.5em;
            min-width: 5em;
            &:nth-of-type(3) {
                min-width: 10em;
            }
            @media only screen and (max-width: 1100px) {
                margin-left: 1em;
            }
        }
        .product-summary-primary {
          .summary-primary-top-row {
            margin: 0.5em 0.9em 0.5em 0.9em;
            @media only screen and (min-width: 1000px) {
                display: flex;
                justify-content: space-between;
            }
          }
        }
      
        .product-summary-secondary-info-container {
          margin: 0.5em 0.9em 0.5em 0.9em;
          @media only screen and (min-width: 1000px) {
          display: flex;
          }
          border: 1px solid $color-grey-1;
          border-radius: 2px;
          align-self: center;
          div {
            padding: 0.5em;
          }
          background-color: $colour-white-turquoise;
          margin-bottom: 1em;
        }
    }
}

