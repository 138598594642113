.mobile-tablet-tab-headers {
  padding-top: 0.25rem;
  .tab-headers {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}
.tab-container {
  margin-left: 1em;
  display: inline-block;
  &.active {
    border-bottom-style: solid;
    border-bottom-color: $colour-standard-turquoise;
    border-bottom-width: 3px;
  }
}
.efa-rates-section {
  height: fit-content;
  overflow-y: hidden;

  width: 51rem;
  min-width: 51rem;
  overflow-x: auto;
  border-radius: 5px;
  box-shadow: 5px 5px 22px #0000001a;
  padding: 0em 1em;

  h4 {
    font-size: 16px;
    max-width: 50%;
  }

  a {
    cursor: pointer;
    color: $colour-standard-turquoise;
    font-size: 16px;
    font-weight: bold;

    &:hover {
      color: Lighten($colour-standard-turquoise, 20%);
    }
    &.small {
      font-size: 13px;
    }
    &.ui.label,
    &.ui.label:hover {
      border-radius: 0;
      border: 0;
      box-shadow: none !important;
      color: Lighten($colour-standard-turquoise, 20%);
      color: white;
      & > .icon.delete {
        opacity: 1;
      }
    }
  }
  .ui.grid + .grid {
    margin-top: 0 !important;
    border-top: solid 4px $colour-offwhite;
  }
  .ui.grid > .row {
    padding: 0.4rem 0 0.4rem 0;
  }
  .grid.rates-table .row {
    margin-top: 0;
    &:nth-child(even) {
      background-color: $colour-offwhite;
    }
    &:nth-child(odd) {
      background-color: white;
    }
  }

  .grid.rates-table-header {
    margin-bottom: 0 !important;
    margin-top: 0.1em !important;
    background-color: white;
    padding-top: 0.1em;
    padding-bottom: 0.1em;
    .row {
      padding-top: 0.1em;
      padding-bottom: 0.1em;
      font-weight: bold;
    }
  }

  .ui.form .grid.rates-table {
    margin-top: 0 !important;
    padding-bottom: 1rem;
    min-width: 40rem;
    div.ui.input > input {
      padding-left: 0.5rem !important;
      padding-right: 0.2rem !important;
    }
    > .column > .row:first-child {
      padding-top: 0;
      height: 1.8rem !important;
    }

    .four.wide.column .row.ip-syndicate,
    .two.wide.column .row.ip-syndicate {
      border-top-style: solid;
      border-color: $colour-white-turquoise;
      border-width: 3px;
      > p {
        color: $color-orange;
        font-weight: bold;
      }
    }

    & .four.wide.column,
    .two.wide.column {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;
      padding-top: 0;
    }
    & .four.wide.column {
      > .row {
        height: 2.5rem;
        display: flex;
        align-items: center;

        &.total > p {
          font-weight: bold;
        }

        &:first-child {
          height: 2.5rem;
        }
        > p {
          padding-left: 1.5em;
        }
      }
    }
    & .two.wide.column {
      > .row {
        height: 2.5rem;
        align-items: center;
        display: flex;
        > .cin.number-formatter {
          padding-top: 0.5em;
          padding-bottom: 0.5em;
        }
        > .field > .ui.input {
          width: 90% !important;

          > input {
            border: 0.5px solid $colour-light-blue;
            padding-right: 1em;
            width: calc(100% - 40px);
          }

          & .ui.label.label {
            border: 0.5px solid $colour-light-blue;
            border-left-width: 0;
            padding-left: 0 !important;
            padding-right: 0.5rem;
            background-color: white;
            color: black;
          }
        }
        &:last-child > span {
          font-weight: bold;
        }
        &:first-child {
          > p {
            padding-top: 0;
            font-weight: bold;
          }
        }
      }
    }

    & .row:first-child {
      border-bottom: 3px solid $background-color;
      background-color: white !important;
    }
    & .row:nth-child(odd) {
      background-color: $background-color;
    }
  }
}
