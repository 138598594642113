.dealer-liquidity-section {
  height: fit-content;
  overflow-y: hidden;

  width: 51rem;
  overflow-x: auto;
  border-radius: 5px;
  box-shadow: 5px 5px 22px #0000001a;
  padding: 0em 1em;

  .title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h4 {
      font-size: 16px;
    }
    .subtitle {
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 15em;
      margin-top: 0.5rem;
      overflow: hidden;
    }
    button {
      margin-top: 0.5em;
      img {
        padding-left: 0.5em;
      }
      &.cancel {
        background: transparent;
      }
    }
  }
  .grid.dealer-liquidity-charge-table .row {
    margin-top: 0;
    &:nth-child(odd) {
      background-color: $table-alternate-row-grey;
    }
  }

  .ui.form .grid.dealer-liquidity-charge-table {
    margin-top: 0;
    padding-bottom: 1rem;
    min-width: 40rem;
    div.ui.input > input {
      padding-left: 0.5rem !important;
      padding-right: 0.2rem !important;
    }
    > .column > .row:first-child {
      padding-top: 0;
      height: 1.8rem !important;
    }

    .row.ips-v-minimum {
      height: 3.5rem !important;
    }
    .four.wide.column .row.ips-v-minimum {
      width: 8em;
    }

    .four.wide.column .row.ip-syndicate,
    .two.wide.column .row.ip-syndicate {
      border-top-style: solid;
      border-color: $colour-white-turquoise;
      border-width: 3px;
      > p {
        color: $color-orange;
        font-weight: bold;
      }
    }

    & .four.wide.column,
    .two.wide.column {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;
      padding-top: 0;
    }
    & .four.wide.column {
      > .row {
        height: 2.5rem;
        display: flex;
        align-items: center;

        &.total > p {
          font-weight: bold;
        }

        &:first-child {
          height: 2.5rem;
        }
        > p {
          padding-left: 1.5em;
        }
      }
    }
    & .two.wide.column {
      > .row {
        height: 2.5rem;
        align-items: center;
        display: flex;
        > .cin.number-formatter {
          padding-top: 0.5em;
          padding-bottom: 0.5em;
        }
        > .field > .ui.input {
          width: 90% !important;

          > input {
            border: 0.5px solid $colour-light-blue;
            padding-right: 1em;
            width: calc(100% - 40px);
          }

          & .ui.label.label {
            border: 0.5px solid $colour-light-blue;
            border-left-width: 0;
            padding-left: 0 !important;
            padding-right: 0.5rem;
            background-color: white;
            color: black;
          }
        }
        &:last-child > span {
          font-weight: bold;
        }
        &:first-child {
          > p {
            padding-top: 0;
            font-weight: bold;
          }
        }
      }
    }

    & .row:first-child {
      border-bottom: 3px solid $background-color;
      background-color: white !important;
    }
    & .row:nth-child(odd) {
      background-color: $background-color;
    }
  }
}
