.dealer-office-content {
    max-width: 120rem;
}

.supplier-office-form-modal{
    div.fields{
        place-content: space-between;
        .small-form-field{
            width: 8em !important;
        }
        .medium-form-field{
            width: 13em !important;
        }
        .large-form-field{
            width: 19em !important;
        }
        .largest-form-field{
            width: 23em !important;
        }
    }
}