.schedules-cfa-container{
    .ui.menu{
      width: 13.4em;
      margin: 10px auto ;
      .item{
        font-size: 12px;
      }
      .active.item{
        background-color: $colour-white-turquoise;
      }
    }

    .cfa-summary-header{
      max-width: 90em;
      margin-top: 1em!important;
    }

    .amounts-collected-table{
      max-width: 65em;
      min-width: 45em;
      margin-top: 2em!important;
      margin-left: 0em!important;
      margin-right: 2em!important;
      .no-bottom-border{
        border-bottom: 0px!important;
      }
    }

    div.content-panel.outline-grid .ui.table.additional-charge-table{
      max-width: 35em;
      margin-top: 2em !important;
      min-width: 20em;
      margin-left :0em !important;

    }

    .accounts-remaining-table{
      max-width: 55em;
      margin-top: 2em!important;
      margin-left: 0em!important;
    }

    div.content-panel.outline-grid .ui.table.fmv-table{
      max-width: 40em;
      margin-left: 0em !important;
      margin-right: 2em !important;
      margin-top: 0em !important;
      margin-bottom: 2em !important;
    }


    .ui.table.unconverted-fmv-table thead tr {
      position: sticky !important;
      top: 0em;
      z-index: 1;
    }

    .ui.table.unconverted-fmv-table thead tr:nth-child(2)  {
      position: sticky !important;
      top: 2em;
      z-index: 1;
    }

    .ui.table.unconverted-fmv-table  tr:last-child{
      position: sticky !important;
      bottom: 0em;
    }

    .unconverted-fmv-table{
      min-width: 55em;
      margin-left: 0em !important;
    }
    @media only screen and (min-width: 900px) {
      .vertical-scrollbar{
        overflow-x: hidden;
      }
    }

    .fmv-container{
      margin-top: 1em;
    }


    .sub-total-row td{
      background-color: $color-grey-7;
      font-weight: bold;
    }
    .total-row td{
      background-color: $color-grey-7;
      font-weight: bold;
    }
    .bold td{
      font-weight: bold;
    }
    .flex{
      align-items: flex-start;
      flex-wrap:wrap;
    }


    
  .ui.table.cfa-detail-table{
    border: 0px !important;
    thead th:nth-child(10),tr:nth-child(even) td:nth-child(10){
        background-color: $color-light-yellow ;
    }
    tbody tr:nth-child(1) td{
      background-color: $colour-dark-turquoise;
      color: $colour-white;
      font-weight: bold;
    }

    &.co-funded tbody tr:nth-child(1) td{
      background-color: $colour-white !important;
      color: $colour-black;
      font-weight: normal;
    }
    &.co-funded tbody tr td i{
      display: none;
    }
    
    tbody tr:last-child td, tbody tr:last-child td:nth-child(10){
      background-color: $color-grey-7;
      font-weight: bold;
      color: $colour-black;
    }
    thead th:nth-child(1), tbody td:nth-child(1){
      border-left: 1px solid $color-grey-1;
    }
    tbody tr:nth-last-child(2) td,  tbody tr:nth-last-child(2)  td:nth-child(10)
    {
      background-color: $colour-white !important;
      border-right: 0px !important;
      border-left: 0px;
      i {
        display: none;
      }
    }
    
    thead th:last-child, tr td:last-child
    {
      background-color: $colour-white !important;
      border: 0px !important;
    }
    
    tbody tr:last-child{
      i {
        display: none;
      }
    }

    tbody tr:last-child, tbody tr:nth-last-child(2) {

    td:nth-child(1) ,  td:nth-child(2) 
    ,  td:nth-child(3) , td:nth-child(4) 
    ,  td:nth-child(5) , td:nth-child(7){
      background-color: $colour-white !important;
      border: 0px !important;
    }

  
  }
  &.total{
    thead{
      display: none;
    }
    tbody tr:last-child {
      td:nth-child(1){
        width: 4.8em;
        background-color: $color-turquoise-highlight!important;
      }
      td:nth-child(2){
        width: 6.6em;
        background-color: $color-turquoise-highlight!important;
      }
      td:nth-child(3){
        width: 6em;
        background-color: $color-turquoise-highlight!important;
      }
      td:nth-child(4){
        width: 4.7em;
        background-color: $color-turquoise-highlight!important;
      }
      td:nth-child(5){
        width: 5.5em;
        background-color: $color-turquoise-highlight!important;
      }
      td:nth-child(6){
        width: 4.9em;
        background-color: $colour-white!important;
      }
      td:nth-child(7){
        width: 5.6em;
        background-color: $color-turquoise-highlight!important;
      }
      td:nth-child(8),   td:nth-child(10),  td:nth-child(12),
      td:nth-child(13), td:nth-child(15){
        width: 5.6em;
        background-color: $colour-white!important;
      }
      td:nth-child(9){
        width: 6.4em;
        background-color: $colour-white!important;
      }
      td:nth-child(11){
        width: 5.1em;
        background-color: $colour-white!important;
      }
      td:nth-child(14){
        width: 7.6em;
        background-color: $colour-white!important;
      }
      td:nth-child(16){
        width: 2.62em;
      }
      td:last-child{
        background-color: $color-turquoise-highlight!important;
      }
    }
  }
}
}


.schedules-cfa-options-container{
  .ui.menu {
    width: 22.2em;
    margin: 0em auto 1.5em auto;
    .active.item{
      background-color: $colour-white-turquoise;
    }
}

.ffDHgC .ui.input{
    width: 13em!important;  
  }

  .cfa-payment-table{
    .highlight, td.highlight{
      background-color: $colour-dark-turquoise !important;
      text-align: center !important;
      border: 1px solid $colour-dark-turquoise !important ;
      border-radius: 5px;
      color: $colour-white;
      font-weight: bold;
      font-size: 1em!important;
    }
    border-top: 0px;
    border-left: 0px !important;
    .empty td{
      border-left: 0px;
    }
    

    tr td:first-child{
      border-left: 1px solid $color-grey-1;
    }
    thead th.border-left{
      border-left: 1px solid $color-grey-1 !important;
    }    
   
    tr:last-child td{
      background-color: $table-footer-grey;
      font-weight: bold;
    }
    tr:nth-last-child(2) td{
      border-left: 0px;
      border-right: 0px !important;
      background-color: $colour-white;
    } 
  }
}
.schedule-cfa-options-modal{
  .ui.button.positive{
    display: none!important;
  }
  .ui.form .disabled.field,.ui.disabled.input{
    opacity: 1;
  }
  @media only screen and (min-width: 1300px){
    &.ui.modal {
        width: 1290px;
        margin: 0em 0em 0em 0em;
    }
    }
    @media only screen and (min-width: 1100px){
      &.ui.modal {
          width: 1090px;
          margin: 0em 0em 0em 0em;
      }
    }

  .fmv-table{
    border-left: 0px !important;
    border-right: 0px !important;
    tr td:first-child{
      border-left: 1px solid $color-grey-1;
    }
    thead th{
      border-left: 1px solid $color-grey-1 !important;
    }    
    tr:nth-last-child(2) td{
      border-left: 0px;
      border-right: 0px !important;
      background-color: $colour-white;
    } 
    tr:last-child td{
      background-color: $table-footer-grey;
      font-weight: bold;
    }
  }
}


