$colour-border-dark: $color-grey-4;
$colour-unused-cell: $colour-grey;

.schedule-dropdown-wrapper {
  display: flex;
  align-items: center;

  h3 {
    margin-bottom: 10px;
    margin-right: 20px;
  }

  //No longer working
  div.item[status="DEAD"] {
    span::after {
      content: " - DEAD";
      color: red;
    }
  }

  .ui.dropdown {
    margin-bottom: 10px;
  }
}

.cfa-schedule-details {
  .content-panel {
    margin-bottom: 10px;
  }

  .grid .column:first-child {
    max-width: 500px;
  }

  .grid .column:nth-child(2) {
    max-width: 450px;
  }

  .ui.table {
    width: auto !important;
    margin: 0 !important;
    margin-bottom: 20px !important;
    border: none !important;
    border-collapse: collapse !important;

    tr td {
      width: 200px;
      padding: 0px;
    }

    tr td:first-child {
      text-align: left !important;
    }

    .italics {
      padding-left: 30px !important;
      font-style: italic;
    }

    .teal {
      background-color: $colour-standard-turquoise !important;
      color: white;

      td:not(.no-border),
      th:not(.no-border) {
        background-color: $colour-standard-turquoise !important;
        color: white;
      }
    }

    tr.highlighted td {
      background-color: $colour-white-turquoise;
    }

    tr.grey td:not(.no-border) {
      background-color: $color-grey-1;
    }

    tbody tr td {
      border-right: none !important;
      border-bottom: none !important;
    }

    td.no-border {
      border: none;
      background-color: white;
    }

    tbody tr td:not(.no-border) {
      border-left: solid 1px $colour-border-dark !important;
      border-right: solid 1px $colour-border-dark !important;
    }

    tr:first-child td:not(.no-border) {
      border-top: solid 1px $colour-border-dark !important;
    }

    tr:nth-last-child(2) td {
      border-bottom: solid 1px $colour-border-dark !important;
    }

    tr:last-child td:not(.no-border) {
      border-bottom: solid 1px $colour-border-dark !important;
    }

    th {
      border: solid 1px $colour-border-dark !important;
    }
  }
}

.cfa-schedule-information {
  h5 {
    text-decoration: underline;
  }
}

.cfa-schedule-details-form {
  label {
    min-width: 200px;
  }

  .red {
    color: red;
  }
}

table.cfa-continuing-payment-split-table {
  tr:nth-child(2) td:not(.no-border) {
    border-top: solid 1px $colour-border-dark !important;
  }
}

table.cfa-cfa-payment-table tr td:first-child {
  width: 400px !important;
}

table.cfa-tmv-table tr td:first-child {
  width: 400px !important;
}

table.cfa-quarterly-managed-volume-table tr td:first-child {
  width: 400px !important;
}

table.cfa-schedule-finance-amounts-collected-table {
  tr:nth-child(2) td:nth-child(2),
  tr:nth-child(2) td:nth-child(3),
  tr:nth-child(7) td:nth-child(2),
  tr:nth-child(7) td:nth-child(3) {
    background-color: $colour-unused-cell !important;
    border-bottom: $colour-unused-cell !important;
  }

  tr:nth-child(3) td:nth-child(2),
  tr:nth-child(3) td:nth-child(3),
  tr:nth-child(8) td:nth-child(2),
  tr:nth-child(8) td:nth-child(3) {
    background-color: $colour-unused-cell !important;
  }

  tr:nth-child(4) td,
  tr:nth-child(5) td {
    border: solid 1px $colour-border-dark !important;
  }
}

table.cfa-schedule-finance-amounts-remaining-table {
  tr:nth-child(2) td:nth-child(2),
  tr:nth-child(3) td:nth-child(2) {
    background-color: $colour-unused-cell !important;
    border-color: $colour-unused-cell !important;
  }
}
