// highlighted inputs - ensure input fields have turquoise borders
.ui.input.highlighted-input,
.ui.input.highlighted-input:focus,
.ui.dropdown.input.highlighted-input,
.ui.form .ui.dropdown.multiple.highlighted-input,
.ui.form .ui.dropdown.selection.highlighted-input,
.ui.form textarea.highlighted-input,
.ui.form.highlighted-inputs .ui.input > input[type="text"],
.ui.form.highlighted-inputs .ui.input > input[type="text"]:focus,
.ui.form.highlighted-inputs .ui.dropdown,
.modal.form.paper-inclusive-step > .ui.dropdown,
.ui.form.highlighted-inputs textarea {
  border: 1px solid $colour-standard-turquoise;
  border-radius: 5px;
  > input {
    &[type="text"] {
      border: none;
    }
  }
}

.blue {
  .ui.input.highlighted-input,
  .ui.input.highlighted-input:focus,
  .ui.dropdown.input.highlighted-input,
  .ui.form .ui.dropdown.multiple.highlighted-input,
  .ui.form textarea.highlighted-input,
  .ui.form.highlighted-inputs .ui.input > input[type="text"],
  .ui.form.highlighted-inputs .ui.input > input[type="text"]:focus,
  .ui.form.highlighted-inputs .ui.dropdown,
  .modal.form.paper-inclusive-step > .ui.dropdown,
  .ui.form.highlighted-inputs textarea {
    border: 1px solid $color-standard-blue;
  }
}

.ui.form.highlighted-inputs .ui.checkbox .box::before,
.ui.checkbox label::before,
.ui.form.highlighted-inputs .ui.checkbox input:checked ~ .box::before,
.ui.form.highlighted-inputs .ui.checkbox input:checked ~ label::before,
.ui.form.highlighted-inputs .ui.selection.active.dropdown .menu,
.ui.dropdown.highlighted-input .ui.selection.active.dropdown .menu {
  border-color: $colour-standard-turquoise;
}

.ui.form.highlighted-inputs .ui.radio.checkbox input:checked ~ .box::after,
.ui.form.highlighted-inputs .ui.radio.checkbox input:checked ~ label::after {
  background-color: $colour-standard-turquoise;
}

.ui.form .field:not(.inline) > .ui.checkbox {
  margin: 0.5em 0;
}

//search field - positions icon correctly
.ui.form .field > .ui.icon.input.search-input input {
  width: 100%;
  margin-right: -2em;
}

.content-panel .ui.dropdown:not(.item) {
  background-color: white;
  border-width: 1px;
  border-style: solid;
  border-color: $color-grey-1;
  padding: 0.1rem 0.5rem;
  border-radius: 0.25rem;
}

// Responsive labels
.field .default-grid-field-label-container {
  font-weight: bold;
  p {
    display: inline;
  }

  @include breakpoint-sm {
    display: flex !important;
    flex-direction: column !important;
    justify-content: flex-end !important;
  }
}
.ui.form .field.fix-input-container > .ui.input.fix-input-wrapper > input,
.ui.form
  .field.fix-input-container
  > .ui.input.fix-input-wrapper
  > .ui.label.currency-label {
  height: 1.75em !important;
}

// form fields
.ui.form .field {
  .ui.input > input,
  textarea,
  .ui.selection.dropdown {
    padding: 0.25em 0.75em !important;

    .text {
      text-overflow: ellipsis;
    }
  }
  .ui.search.selection.dropdown > input {
    padding: 0 0 0 0.75em !important;
  }
  .ui.right.labeled.input > input {
    padding-right: 0 !important;
  }
  .ui.input > .ui.label:not(.currency-label) {
    padding: 0.25em !important;
  }
  .ui.input > .ui.label.currency-label {
    padding: 0.2em 0 0.2em 0.2em;
  }
  .ui.left.labeled.input > .ui.label {
    line-height: 1.2em;
  }

  .ui.selection.dropdown {
    min-height: unset;
    padding-right: 24px !important;
    i.dropdown.icon {
      padding: 0.5em;
    }

    input {
      padding: 0.25em 0.75em !important;
    }

    &.multiple {
      padding-right: 1.75rem !important;
      > .ui.label {
        background-color: $colour-lighter-blue;
        color: $text-color;
        border-radius: 0.2rem;
        font-weight: normal;
      }
    }
  }
}

.cin.side-panel.users-filter-side-panel,
.cin.side-panel.sheets-filter-side-panel,
.cin.side-panel.schedules-list-filter-side-panel,
.cin.side-panel.price-increase-filter-side-panel,
.cin.side-panel.cfa-list-filter-side-panel {
  .ui.grid {
    margin: 0 !important;
    .column {
      padding: 0 !important;
    }
  }

  .ui.dropdown {
    min-height: 1.75rem !important;
  }
  .filters-title {
    display: flex;
    align-items: center;
  }
  .clear-button,
  .select-all-button {
    margin-left: 0.5rem;
    cursor: pointer;
    color: $colour-standard-turquoise;
    font-size: 16px;
    &:hover {
      color: lighten($colour-standard-turquoise, 10%);
    }
  }

  .field {
    margin-bottom: 1rem !important;
  }
  .ui.checkbox {
    margin-bottom: 0 !important;
  }
}

.cin.side-panel.schedules-list-filter-side-panel,
.cin.side-panel.price-increase-filter-side-panel,
.cin.side-panel.cfa-list-filter-side-panel {
  width: 500px;

  .cin.form-clearer .ui.tiny.basic.circular.icon.button {
    float: right;
  }
}

.search-filter-display {
  display: flex;

  > .filter-display {
    margin-left: 1rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .filter-display-item {
      background-color: $colour-standard-turquoise;
      border-radius: 0.25rem;
      color: white;
      padding: 0 0.5rem;
      height: 1.5rem;
      font-size: 12px;
      margin: 0 0.25rem;
      max-width: 10rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .clear-button {
      margin-left: 0.5rem;
      cursor: pointer;
      color: $colour-standard-turquoise;
      font-size: 16px;
      line-height: 1.75rem;
      &:hover {
        color: lighten($colour-standard-turquoise, 10%);
      }
    }
  }
}

// Prevent opacity changing for disabled fields
.ui.form .disabled.field,
.ui.form .disabled.field > label,
.ui.form .disabled.field .ui.checkbox input[disabled] ~ label {
  opacity: 1;
}

.ui.multiple.dropdown > .text {
  margin: 0 !important;
}
