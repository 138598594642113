.schedules-container{
  padding: 0px 10px 10px 10px ;
  max-width: 130em;
  margin: auto;
}


.tbc {
  color: $color-red;
}

.content-panel.charges {
  .ui.table {
    .bottom-row.header th.visible {
      padding: 0.5em 0.75em !important;
    }
  }
}

div.content-panel {
  .ui.positive.button {
    background-color: $colour-dark-turquoise;
  }
}

//outline grid
div.content-panel.outline-grid,
.modal .liability-csb,
.ui.active.modal
 {
  .accordion.ui {
    margin-top: -0.5em !important;
  }
  .strong {
    font-weight: bold;
  }

  .right {
    float: right;
  }

  .ui.table {
    border-left: 1px solid $color-grey-1;
    margin: auto;

    &.header-tbl {
      float: left;
      thead th {
        background-color: $colour-white;
      }
    }
    .left-align {
      text-align: left;
    }

    th.small.wide {
      width: 5%;
    }

    th.smaller.wide {
      width: 4%;
    }

    th.smallest.wide {
      width: 3%;
    }
    th.tiny.wide {
      width: 1%;
    }

    thead th {
      border-top: 1px solid $color-grey-1;
      background-color: $colour-white-turquoise;
      border-bottom: 1px solid $color-grey-1;
      border-right: 1px solid $color-grey-1;
      font-size: 12px;
      font-weight: bold;
      text-align: center;
      &.square {
        border-radius: 0px;
      }
    }

    tbody tr td.empty,
    thead th.empty {
      border-top: none;
      border-bottom: none;
      background-color: #fff;
    }

    thead th:first-child {
      padding-left: 9.5px;
    }

    tbody tr td,
    tbody thead th {
      border-bottom: 1px solid $color-grey-1;
      border-right: 1px solid $color-grey-1;
      font-size: 12px;
      text-align: center;
    }
    tbody tr td:first-child {
      padding-left: 9.5px;
    }
    td.yes {
      background-color: $color-light-green;
      font-weight: bold;
    }
    td.no {
      background-color: $colour-light-grey;
    }

    tbody tr td.reduced-padding {
      padding: 2px;
    }
    tbody tr td.highlight {
      background-color: $colour-white-turquoise;
      padding: 0px;
    }
    tbody tr td.red-highlight {
      background-color: $color-red-light;
    }

    tbody tr td.no-padding {
      padding: 0px;
    }
    td.blue,
    .blue td,
    .blue td a {
      color: blue;
    }
    a {
      text-decoration: underline;
    }
    a:hover {
      text-decoration: none;
    }
    tbody tr:nth-child(even) {
      background: $table-alternate-row-grey;
    }
  }

  .retracted {
    height: 350px;
  }
  .extended {
    height: auto;
  }

  .extension-icon {
    text-align: right;
    margin-right: 3em;
    button {
      margin: 5px;
      padding: 5px;
      i {
        margin: 0 !important;
      }
    }
  }

  .content-panel.outline-grid .ui.table.custom-fc-table {
    border: none;
    width: 75%;
    margin: auto;
    th {
      border-top: 1px solid $color-grey-1;
      text-align: left;
    }
    th:first-child {
      border-top: 1px solid $colour-white;
      border-left: none;
      background-color: $colour-white;
    }
    td:first-child {
      background-color: $colour-standard-turquoise;
      color: $colour-white;
      font-weight: bold;
    }
  }

  .ui.table.left-align-header {
    th {
      text-align: left;
    }
  }

  .ui.table.no-indent t:first-child,
  .ui.table.no-indent th:first-child {
    padding-left: 3px;
  }

  .ui.table.collapsable-headers {
    border-left: none;
    border-top: none;
    tr td:first-child,
    tr th:first-child {
      border-left: 1px solid $color-grey-1;
    }
    .hide tr th {
      border-left: none;
    }
    .hide th {
      border-top: none !important;
      border-left: none;
      border-right: none;
      background-color: $colour-white;
      padding: 0px;
      margin: 0px;
    }

    .grey {
      background-color: $light-grey;
      margin: 0px 10px;
      border: 1px solid $color-grey-1;
      border-bottom: none;
      border-radius: 10px 10px 0px 0px;
      padding: 10px;
    }
  }

  .defaults-page-title {
    background-color: $colour-white-turquoise;
    padding: 10px 20px 10px 20px;
    margin-top: -7px;
    &.blue{
      background-color: $color-standard-light-blue;
    }
    &.yellow{
      background-color: $color-lighter-yellow;
    }
    &.violet{
      background-color: $colour-light-violet;
    }
  }

  .ui.three.column.grid.highlight-background {
    background-color: $colour-white-turquoise;
  }

  .ellipsis.horizontal.icon {
    font-size: 2em;
    color: $colour-light-blue;
    padding-top: 0.2em;
  }

  .gridMenu .ui.dropdown:not(.item) {
    background-color: none;
    border-width: 1px;
    border-style: none;
    border-color: $colour-white;
  }

  span.green {
    color: $color-green;
    padding: 0px;
    margin: 0px;
    margin-top: -4px;
    margin-bottom: -4px;
    display: block;
  }

  .center {
    text-align: center;
    margin: auto;
    font-size: 2em;
  }

  .center .dropdown.small {
    font-size: 1em;
  }

  .ui.compact.dropdown:not(.item) {
    border: none;
    background-color: transparent !important;
  }
}

//blue outline grid
.blue.content-panel.outline-grid
{
    thead th {
      background-color: $color-standard-light-blue !important;
    }
  }
  .yellow.content-panel.outline-grid{
    thead th{
      background-color: $color-lighter-yellow !important;
    }
  }
  .violet.content-panel.outline-grid{
    thead th{
      background-color: $colour-light-violet !important;
    }
  }
.ui.filtered-grid.menu {
  width: 289px;
  margin: 10px auto;
  .item {
    font-size: 12px;
  }
  .active.item {
    background-color: $colour-white-turquoise;
  }
}

.filtered-grid {
  .content-panel .ui.dropdown:not(.item) {
    max-width: 100%;
  }

  .ui.input,
  .ui.form .field .ui.dropdown {
    max-width: 100% !important;
  }
  .icon-lrg .icon {
    font-size: 1.8em;
  }
}

.filters {
  margin: 0em 1em 2em 1em;
  padding: 1em 1em 2em 1em !important;
  .ui.grid > .column:not(.row) {
    padding: 0.3em;
  }
  .field {
    padding-bottom: 5px;
  }
  &.slim {
    max-width: 1200px;
    margin: auto;
  }
}

.filtered-grid {
  .content-panel .ui.dropdown:not(.item) {
    max-width: 100%;
  }

  .ui.input,
  .ui.form .field .ui.dropdown {
    max-width: 100% !important;
  }
  .icon-lrg .icon {
    font-size: 1.8em;
  }
  .ui.menu {
    width: 289px;
    margin: 10px auto;
    .item {
      font-size: 12px;
    }
    .active.item {
      background-color: $colour-white-turquoise;
    }
  }
}

.right-align {
  text-align: right;
}

.left-align {
  text-align: left;
}

.filters {
  padding: 1em 1em 1em 1em !important;
  .ui.grid > .column:not(.row) {
    padding: 0.3em;
  }
}

.ui.table.details {
  tr,
  td,
  th {
    background-color: #fff;
    font-weight: normal;
  }
  td,
  th {
    border-bottom: 1px solid $color-grey-1 !important;
    border-right: 1px solid $color-grey-1 !important;
    padding: 5px 10px;
  }
  th:first-child {
    border-left: 1px solid $color-grey-1 !important;
    border-bottom: 1px solid $color-grey-1 !important;
    border-right: 1px solid $color-grey-1 !important;
    padding: 5px 10px;
  }
  tr:first-child td,
  tr:first-child th {
    border-top: 1px solid $color-grey-1 !important;
  }
  td {
    text-align: right;
  }
  td.left {
    text-align: left;
  }
  td.center {
    text-align: center;
  }
  tr:first-child td.no-border {
    border: none !important;
  }
  .strong td,
  .strong tr,
  .strong th,
  tr.strong td,
  tr.strong th {
    border: 1px solid $colour-black !important;
    font-weight: bold;
  }
  tr.no-border td,
  tr.strong td.no-border {
    border-right: none !important;
  }

  td.no-border,
  tr.strong td.no-border {
    border: none !important;
    font-weight: normal;
  }
  &.csd {
    max-width: 750px;
    tr:first-child th {
      border-bottom: none !important;
      text-align: center;
      font-size: 1em;
    }
    tr {
      td:first-child {
        border-left: 1px solid $color-grey-1 !important;
        text-align: left;
      }
      &.no-border td:first-child {
        border-left: none !important;
      }
      &.footer td {
        border-top: 1px solid $color-grey-1 !important;
        &.empty {
          border: none !important;
        }
      }
    }
  }
}

.container {
  .defaults-page-title {
    background-color: $colour-white-turquoise;
    padding: 10px 20px 10px 20px;
    margin-top: -7px;
  }
}

.liability-container, .price-increase-container {
  .ui.dropdown.cin.action-menu {
    float: right;
    border: none;
    button {
      background-color: $colour-standard-turquoise;
      color: $colour-white;
    }
    padding-bottom: 10px;
  }

  .ui.attached.tabular.menu {
    .item {
      width: 50%;
    }
  }
}

.ui.attached.tabular.menu {
  background-color: $colour-white-turquoise;
  border-top: 4px solid $colour-standard-turquoise;
  border-radius: 5px 5px 0px 0px;
  font-size: 1.2em;
  font-weight: bold;
  font-size: 1.2em;
  .active.item {
    background-color: $colour-standard-turquoise;
    color: $colour-white;
    margin-top: -5px;
  }
}

.schedule-meter-reading-utility-bar {
  .ui.primary.button {
    margin: 2px 0px 0px 5px;
  }
}

.schedule-charges {
  button.ui.primary.button {
    color: black;
    background-color: transparent;
    font-size: 1em;
    padding: 0.5em;
    font-weight: bold;
    text-decoration: underline;
    &:hover {
      color: $color-blue-alt;
      background-color: transparent !important;
    }
  }
}

.scts-modal {
  .ui.positive.button {
    background-color: $colour-standard-turquoise !important;
    i {
      display: none;
    }
  }

  .ui.table {
    border-left: 0px;
    border-right: 0px;

    thead tr th {
      border-top: 1px solid #ccc;
      border-left: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      border-right: 1px solid #ccc;
      font-size: 12px;
    }

    tbody tr td {
      border-left: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      border-right: 1px solid #ccc;
      font-size: 12px;
    }

    th {
      text-align: left !important;
      color: black;
      font-weight: bold;
      background-color: $colour-white-turquoise !important;
    }

    td:first-child {
      font-weight: bold;
    }

    tr.no-border {
      border-left: none !important;
      border-right: none !important;

      td {
        &:first-child {
          border-left: none !important;
        }
        &:last-child {
          border-right: none !important;
        }
      }
    }

    tr:last-child {
      background: #f9f9fc;
    }

  
    tr:nth-last-child(2) {
      background-color: transparent;
    }
  }
}


