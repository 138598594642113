.customer-current-spend {
  h5 {
    font-weight: bold;
    text-decoration: underline;
    margin-left: 2em;
  }
  .headers {
    margin-left: 2em;
    display: flex;
    font-weight: bold;
    text-align: center;
    &.no-justify {
      justify-content: unset !important;
    }
    .wide {
      width: 15em !important;
      min-width: 15em !important;
    }
    .xtr-wide {
      width: 20em !important;
      min-width: 20em !important;
    }
    .left {
      text-align: left;
    }
    div {
      text-align: center;
      width: 8em !important;
      min-width: 8em !important;
      margin: 0 10px 5px 0;
    }
  }
  .current-spend-container {
    display: flex;
    .paper-size {
      font-weight: bold;
      padding-right: 5px;
    }
    .current-spend-columns {
      display: flex;
      input {
        opacity: 1 !important;
      }
      .wide {
        width: 15em !important;
        .ui.input {
          width: 15em !important;
        }
      }
      .xtr-wide {
        width: 20em !important;
        .ui.input {
          width: 20em !important;
        }
      }
      .left {
        text-align: left;
      }
      .value {
        width: 8em;
        margin: 0 12px 5px 0;
      }
    }
  }
}

.paper-schedule {
  .disabled.field,
  .ui.disabled.input {
    opacity: 1 !important;
  }
  .cin.tile {
    padding: 0.3em;
    margin: 0em;
  }
  .cin.tile h4 {
    font-size: 12px;
    margin-bottom: 0em;
  }
  .ui.table {
    border-bottom: none !important;
  }
  .right-border {
    border-right: 1px solid #d9f1f1;
  }
  .small-left {
    width: 100px;
    float: left;
  }
  .medium-left {
    width: 200px;
    float: left;
  }
  .large-left {
    width: 300px;
    float: left;
  }
  .centered-content {
    span {
      width: 30px;
      display: inline-block;
    }
  }
  .large-centered {
    max-width: 1000px;
    margin: auto;
  }
  .rewrite-width {
    max-width: 450px;
  }
  .admin-width {
    max-width: 1000px;
    margin: auto;
  }
  .admin-min-width {
    min-width: 950px;
  }
}

.additional-costs {
  .add-cost-headers {
    display: flex;
    font-weight: bold;
    div {
      text-align: center;
      min-width: 10em;
      width: 10em !important;
      margin: 0 6px 5px 6px;
    }
    .wide {
      width: 15em !important;
      min-width: 15em !important;
    }
    .xtr-wide {
      width: 20em !important;
      min-width: 20em !important;
    }
    .xtr-xtr-wide {
      width: 30em !important;
      min-width: 30em !important;
    }
  }
  .ui.input {
    width: 10em !important;
  }
  .add-cost-all-fields {
    display: flex;
    .array {
      min-width: 47.5em;
      justify-content: space-between;
      .add-cost-fields {
        margin-bottom: 5px;
        display: flex;
        justify-content: space-between;
        .field {
          margin: 0 10px 0 0;

          .ui.input.wide {
            width: 15em !important;
          }
          .ui.input.xtr-wide {
            margin-left: 1em;
            width: 20em !important;
          }
        }
        .ui.input.xtr-xtr-wide {
          margin-left: 1em;
          width: 30em !important;
        }
      }
    }
  }

  .additional-terms {
    height: 55px;
  }
  textarea {
    width: 30em !important;
  }
}
