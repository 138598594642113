.services-list {
    padding: 0.5rem;   
    max-width: 1600px;
}

.ui.form.service-form {
    .ui.radio.checkbox {
        margin-left: 1em; 
    }
    .ui.checkbox:not(.radio) {
        padding: 0.5em 0;
    }
    div.group {
        > div.field {
          margin-right: auto!important;
        }
      }
}