.schedule-table-row {
  margin-left: 2em;
  display: flex;
  .sum-col {
    padding: 5px;
  }
  .sum-col-1 {
    &.SS {
      width: 13em;
    }
    &.MA {
      width: 10em;
    }
  }
  .sum-col-2 {
    font-weight: bold;
    &.SS {
      width: 8em;
    }
    &.MA {
      width: 12em;
    }
  }
  .sum-col-3 {
    width: 1em;
  }
  .sum-col-4 {
    &.SS {
      width: 13em;
    }
    &.MA {
      width: 6em;
    }
  }
  .sum-col-5 {
    font-weight: bold;
    &.SS {
      width: 8em;
    }
    &.MA {
      width: 10em;
    }
  }
  .master-agreement-teal {
    color: $colour-standard-turquoise;
  }
}

.schedule-summary-container {
  .bold {
    font-weight: bold;
  }
  .schedule-summary-primary {
    .summary-primary-top-row {
      display: flex;
      justify-content: space-between;
    }
    .schedule-summary-primary-data {
      display: flex;
      div {
        margin: 0.5em;
      }

      .large-text {
        font-size: 18px;
        width: 8em;
      }
      .schedule-summary-primary-value {
        width: 18em;
        border-bottom: 2px solid $color-grey-1;
        text-align: center;
        align-self: center;

        &.large {
          width: 85%;
        }
      }
    }
  }

  .schedule-summary-secondary-info-container {
    margin: 0.5em 0.9em 0.5em 0.9em;
    display: flex;
    border: 1px solid $color-grey-1;
    border-radius: 2px;
    align-self: center;
    div {
      display: flex;
      padding: 0.5em;
    }
    background-color: $colour-white-turquoise;
    margin-bottom: 1em;
    .secondary-data-container {
      width: 25%;
    }
    .secondary-value {
      align-self: center;
      background-color: white;
      border-bottom: 2px solid $color-grey-1;
      padding: 0.1em 0 0.1em 0;
      width: -webkit-fill-available;
      margin: 0 1em 0 1em;
      justify-content: center;
      font-size: 13px;
    }
    .header {
      min-width: fit-content;
    }
  }

  .schedule-summary-tertiary-info-container {
    margin: 0.5em 1em 0.5em 1em;
    text-align: center;
    font-size: 12px;
    .schedule-summary-block-header {
      display: flex;
      background-color: $colour-white-turquoise;
      font-weight: bold;
      div {
        border: 1px solid $color-grey-1;
      }
      .large-width {
        width: 15.3%;
      }
      .medium-width {
        width: 7.7%;
      }
    }
    .schedule-summary-block-values {
      display: flex;
      div {
        border: 1px solid $color-grey-1;
        padding: 3px 0 3px 0;
      }
      .large-width {
        width: 15.3%;
      }
      .medium-width {
        width: 7.7%;
      }
    }
  }
}

.summary-header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .summary-group {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    .data-field {
      display: flex;
      justify-content: flex-start;
      .value {
        font-weight: bold;
        margin-left: 1rem;
      }
    }
    &.group1 {
      .data-field {
        .field {
          width: 6rem;
        }
        &:first-child {
          margin-bottom: 0.75rem;
        }
      }
    }
    &.group3 {
      min-width: 17rem;
      .data-field {
        justify-content: space-between;
      }
    }
    span {
      &.value {
        font-weight: bold;
      }
      &.field {
        padding-right: 0.5em;
      }
    }
  }

  .bold {
    font-weight: bold;
  }

  @include customBreakpoint("max-width: 880px") {
    flex-direction: column;
    justify-content: flex-start;
    .summary-group {
      .data-field {
        justify-content: flex-start;
        .field {
          width: 10rem !important;
        }
      }
      &.group3 {
        .row {
          justify-content: flex-start;
        }
      }
      &.group1 {
        .row:first-child {
          margin-bottom: 0;
          .data-field {
            justify-content: flex-start;
          }
        }
        &.group1 {
          .data-field:first-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

.schedule-item-table-footer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0rem 2.25rem;

  .schedule-item-extra-fields {
    min-width: 34rem;
    margin-right: 1rem;
    margin-bottom: 0.25rem;
    .extra-fields-row {
      display: flex;
      .col-1 {
        width: 28em;
        margin-right: 1em;
      }
      .col-2 {
        width: 5em;
        text-align: right;
      }
    }
  }
}
