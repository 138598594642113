.dealers-structure{
   padding: 1px;
    font-weight: bold;
    .ui.table tbody tr td{
    font-size: 1em !important;
    }
   td.inline-content{
    display: flex;
    justify-content:flex-start;
    height: 100%;
    span{
        padding: 0em 0em 0.6em 0em;
    }
   }
   .ui.form .field .ui.dropdown > .text {
    padding: 0.15em 0em ;
   }

   .ui.button.new-btn{
       background-color: transparent !important;
       color: $colour-standard-turquoise;
        margin-top: -0.5em;
        &.blue{
            color: $colour-light-blue;
        }
    }
 
    .ui.form .field .ui.input > input {
       height: 2.2em;
   }

   .structure-id-width{
        min-width: 6em;
        white-space: nowrap;
    }
   .structure-level-width{
        min-width: 7em;
    }

}