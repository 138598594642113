.spv-details {
    max-width: 130em;
    margin: auto;
    .ui.menu {
      &:after {
        content: none;
      }
        margin: 10px 0;
        flex-grow: 0;
        .item {
            font-size: 12px;
        }
        .active.item {
            background-color: $color-lighter-yellow;
        }
      }

    .header-text {
      font-weight: bolder;
      font-size: larger;    
  }

  .spv-tabs{
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

.red-highlight {
        color: $color-red;

        &.right {
            text-align: right;
            padding: 2.5em;
            font-size: 1.2em;
            font-weight: bold;
        }
        &.left {
            text-align: left;
            padding: 2.5em;
            font-size: 1.2em;
            font-weight: bold;
        }
    }

.ui.form.spv-details {
        .field .ui.input input,
        .ui.form .field .ui.input,
        .ffDHgC .ui.input,
        .ui.disabled.dropdown,
        .ui.dropdown,
        .react-datepicker-wrapper {
            max-width: 100% !important;
            min-height: 1.75rem !important;
            text-align: center;
        }
        .ui.dropdown {
            max-width: 100% !important;
            min-height: 1.75rem !important;
        }

        .ui.grid>.row {
            padding: 0.1em;
        }
        
        .disabled.field,
        .ui.disabled.input,
        .ui.input:not(.disabled) input[disabled],
        .ui.form .disabled.fields .field,
        .ui.form .disabled.field,
        .ui.form .field :disabled,
        .ui.disabled.dropdown {
            opacity: 1 !important;
        }

        .ui.grid{
            background-color: white !important;
            overflow-y: visible !important;
            margin-bottom: 0.5rem;
        }        
    }
    
    .top-spacer{
        margin-top: 3rem;
    }   
}
.spv-container{
    padding: 0px 10px 10px 10px ;
    max-width: 130em;
    margin: auto;
  }

.spv-details{
  .ui.form .field{
    opacity: 1!important;    
    margin-bottom: 0.5em;
 
  }
  .custom-sublabel .field{
    margin-bottom: 0em !important;
  }
}

.spvs-settings{
  .ui.table td{
    padding:  0.3em 1em !important;
  }
  
  .due-date-container {
    width:3em;
    margin-top: -0.3em !important;
    .ffDHgC .ui.input{
      width: 3em !important;
    }
  }
  div.content-panel .spaced .custom-sublabel{
    margin-bottom: 0.5em;
  }

  .ui.input input{
    text-align: left!important;
  }
 
  .ui.selection.dropdown{
    margin-bottom: 0em;
  }
  .supplier-form{
      max-width: 65em;
    .ui.label:not(.currency-label) {
      width: 35%!important;
    }
    
   .custom-sublabel .read-only{
      padding: 0.25em 0.75em !important;
    }
  }
  
  .ui.form .disabled.field{
    opacity: 1!important;    
    margin-bottom: 0.5em;
 
  }

  .ui.labeled.input.custom-sublabel{
    margin-bottom: 0.5em;
    .disabled.field{
      margin-bottom: 0em;
    }
  }

  .ui.disabled.input{
    opacity: 1!important;
    margin-bottom: 0em;
  }
}
  .spv-summary {
    margin: 10px 10px 5px 0px !important;  
}

  .spv-information{
    max-width: 100% !important;
    margin: auto !important;    
  }

  .spv-billing-information {
    margin-top: 30px !important;  
  }
 
  .background-blue {
    background-color: rgb(162, 196, 201);
  }


  .bank-account-box {
    margin: 4em 1em 5em 5em !important;
    width: calc(100% - 65em) !important;  
    display: flex; 
    border: solid $color-grey-1 1.5px; 

    .bank-account-labels{
      display: grid;
      width: 45%;
      .label-margin{
        padding: 10px 0 0 10px;
      }
      div{
        border-right: solid $color-grey-1 1px;  
        height: 2.75em !important; 
      }
        > div:nth-child(odd) 
        {
          background-color: $colour-light-violet;          
        }  
       > div:nth-child(even) 
        {
          background-color: $light-grey-1;          
        }      
    }  


    .bank-account-fields{
      display: grid;
      width: 55%;   
      .label-margin{
        padding: 10px 0 0 10px;
      }  
      div{         
        height: 2.75em !important; 
        width: 100% !important;
        .ui.input{
          width: 100% !important;
        }
      }        
      > div:nth-child(even) 
      {
          background-color: $light-grey-1;          
      }      
    }
 }